import React, { FC, MouseEvent, ReactNode } from 'react';

import classNames from 'classnames';
import { bool, func, node, string } from 'prop-types';

import { LegacyStyleButton } from '@shared/components/legacy/LegacyStyleButton';

import './index.scss';

import LoadingIcon from '../../Icons/LoadingIcon';

export interface ButtonProps {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  /** not sure we use "size" prop */
  size?: string;
  label?: ReactNode;
  primary?: boolean;
  secondary?: boolean;
  negative?: boolean;
  /** Used when Button is rendered by Dropdown component */
  dropdown?: boolean;
  fluid?: boolean;
  imageButton?: boolean;
  text?: boolean;
  auth?: boolean;
  active?: boolean;
  'data-test-id'?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  inProgress?: boolean;
  id?: string;
  name?: string;
  value?: string;
}

const Button: FC<ButtonProps> = ({
  onClick,
  size,
  label,
  primary,
  secondary,
  negative,
  dropdown,
  fluid,
  imageButton,
  text,
  active,
  auth,
  'data-test-id': dataTestId = 'button',
  children,
  disabled,
  type,
  className,
  inProgress,
  id,
  name,
  value,
}) => {
  return (
    <LegacyStyleButton
      id={id}
      disabled={disabled}
      className={classNames(className, 'button-default', {
        'button-primary': primary,
        'button-secondary': secondary,
        'button-negative': negative,
        'button-dropdown': dropdown,
        'button-fluid': fluid,
        'button-img': imageButton,
        'button-text': text,
        'button-auth': auth,
        size,
        disabled,
        active,
        progress: inProgress,
      })}
      onClick={onClick}
      type={type}
      name={name}
      value={value}
      data-test-id={dataTestId}>
      {children}
      {inProgress ? (
        <LoadingIcon fill={!(primary || negative) ? '#0e75c2' : undefined} />
      ) : (
        label
      )}
    </LegacyStyleButton>
  );
};

Button.propTypes = {
  primary: bool,
  secondary: bool,
  negative: bool,
  /**
   * Used when Button is rendered by Dropdown component.
   */
  dropdown: bool,
  fluid: bool,
  imageButton: bool,
  /**
   * Sets button to look link-styled.
   */
  text: bool,
  auth: bool,
  disabled: bool,
  active: bool,
  size: string,
  inProgress: bool,
  label: node,
  className: string,
  onClick: func,
  'data-test-id': string,
  name: string,
  value: string,
};

export default Button;
