import React from 'react';

import { toast, ToastOptions } from 'react-toastify';

import Notification, { NotificationType } from '../Notification';
import RateApp from '../RateApp';

export const notify = (
  message: string,
  type: NotificationType = 'info',
  { autoClose }: ToastOptions = {},
) =>
  toast(() => <Notification {...{ type }}>{message}</Notification>, {
    className: `toast-item-${type}`,
    autoClose,
  });

const notifyRateToastId = 'notifyRateToastId';
export const handleDismissNotifyRateApp = () =>
  toast.dismiss(notifyRateToastId);

export const notifyRateApp = (
  onRateClick: (rate: number) => void,
  onNeverAskAgainClick: () => void,
  handleSkipClick: () => void,
) =>
  toast(
    <RateApp
      onRateClick={onRateClick}
      onNeverAskAgainClick={onNeverAskAgainClick}
      onDismissNotifyRateApp={handleDismissNotifyRateApp}
      onSkip={handleSkipClick}
    />,
    {
      toastId: notifyRateToastId,
      className: 'toast-item',
      autoClose: 60000,
      delay: 3000,
      closeOnClick: false,
      closeButton: false,
    },
  );

const isRecord = (arg: unknown): arg is Record<string, unknown> => {
  return typeof arg === 'object' && arg !== null && !Array.isArray(arg);
};

export const getErrorString = (error: unknown) => {
  if (typeof error === 'string') return error;
  if (error instanceof Error) return error.message;
  if (isRecord(error) && isRecord(error.errors)) {
    const { errors } = error;
    if (typeof errors.mainMessage === 'string') return errors.mainMessage;
    const candidate = Object.values(errors).find(
      (value) => typeof value === 'string',
    );
    if (typeof candidate === 'string') return candidate;
  }
  return 'Something went wrong!';
};

export const notifyAboutError = (
  error?: string | { errors: { mainMessage: string } } | Error | unknown,
) => {
  const message = getErrorString(error);
  notify(message, 'error');
};

export const notifyAboutSuccess = (message: string) => {
  notify(message, 'success');
};
