import React, { useState } from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';

import {
  authenticated,
  resendAuthCode as resendAuthCodeApi,
  validateAuthCode as validateAuthCodeApi,
} from '../../../../api/authentication';
import AuthPageWrapper from '../../../../components/AuthPageWrapper';
import Button from '../../../../components/ButtonLogin';
import { BUTTON_VARIANT } from '../../../../components/ButtonLogin/constants';
import CodeInput from '../../../../components/CodeInput';
import FormGroup from '../../../../components/Form/FormGroup';
import Link from '../../../../components/Link';
import { useApi } from '../../../hooks/api';

import './index.scss';

import Loading from '../../../../components/Loading';
import NotificationsHolder from '../../../../components/Notifications/NotificationsHolder';
import { notify } from '../../../../components/Notifications/utils';
import { parseQueryString, useNavigate } from '../../../../utils/route';
import {
  getContextAndTargetPath,
  handleRedirectOfTargetUri,
} from '../../utils';

const AuthCodeLogin = ({
  search,
  onManagerPortalLogin,
  onStaffPortalLogin,
}) => {
  const [, validateAuthCode] = useApi(validateAuthCodeApi);
  const [resendStatus, resendAuthCode] = useApi(resendAuthCodeApi);
  const [, handleManagerPortalAuth] = useApi(authenticated);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCodeChange = async (authCode) => {
    if (error) setError(/* resetting error */);
    if (authCode.length === 6) {
      setIsLoading(true);
      try {
        await validateAuthCode(authCode);
        // Staff portal authentication needs separate error handling, should not stop normal login flow.
        try {
          const contextAndTargetPath = getContextAndTargetPath();
          const { data: userAndConfig } = await handleManagerPortalAuth(
            contextAndTargetPath.targetPath,
            contextAndTargetPath.context,
          );
          if (!userAndConfig.targetUri.contains('https://')) {
            onManagerPortalLogin(userAndConfig);
            onStaffPortalLogin();
          }
          handleRedirectOfTargetUri(userAndConfig.targetUri);
        } catch (e) {
          // If user manager auth failed, try staff auth and redirect.
          onStaffPortalLogin(e);
          onManagerPortalLogin({});
          navigate(0);
        }
      } catch (e) {
        const { originalError: { body: response = {} } = {} } = e || {};
        if (response.message) {
          setError(response.message);
        } else {
          setError('Something went wrong, please try again.');
        }
      }
      setIsLoading(false);
    }
  };

  const handleGoBack = () => {
    navigate(parseQueryString(search)?.targetPath || '/');
  };

  const handleResend = async () => {
    try {
      await resendAuthCode();
      notify('login.mfa.resendAuthCode.success', 'success');
    } catch (e) {
      notify('login.mfa.resendAuthCode.error', 'error');
    }
  };

  return (
    <AuthPageWrapper>
      <LegacyDiv className="flex-one">
        <LegacyDiv className="flex-center margin-4-bottom">
          <h1 className="login-header padding-0">Enter Code</h1>
          <h2 className="authCode__description padding-0">
            A message with a verification code has been sent to your device.
            Enter the code to continue.
          </h2>
        </LegacyDiv>
        <LegacyDiv className="flex flex-one flex-column flex-space-between">
          <FormGroup
            feedback={error}
            className="flex-center"
            data-test-id="code-to-continue">
            <CodeInput
              pattern="^[0-9]*$"
              containerClassName="margin-5-top"
              onChange={handleCodeChange}
              disabled={isLoading}
            />
            {isLoading && <Loading className="margin-2-top" delay={0} />}
          </FormGroup>
          <LegacyDiv className="flex-center">
            <Button
              type="button"
              text="Go back"
              onClick={handleGoBack}
              data-test-id="backButton"
              variant={BUTTON_VARIANT.LOGIN}
              shouldGrow
            />
            <p className="margin-2-top padding-0-bottom">
              Didn´t recieve a code?
            </p>
            <LegacyDiv className="authCode__resendContainer">
              {resendStatus.isLoading ? (
                <Loading delay={0} />
              ) : (
                <Link
                  data-test-id="resendCodeButton"
                  onClick={handleResend}
                  external
                  className="link-primary pointer">
                  Resend
                </Link>
              )}
            </LegacyDiv>
          </LegacyDiv>
        </LegacyDiv>
      </LegacyDiv>
      <NotificationsHolder />
    </AuthPageWrapper>
  );
};

export default AuthCodeLogin;
