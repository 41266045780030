import { isEmpty } from '@shared/utils';

import { reactRouterDevToggle } from '@ManagerPortal/featureToggles';

import config from '../../../shared/config';
import ErrorReporting from '../../../shared/utils/errorreporting';
import { getGlobalHistory } from '../../../shared/utils/route/wrappers/BrowserLocationProvider';
import { CONFIG_PROPERTY, DATA_PROPERTY, TRACKER_SETTING } from './constants';
import {
  ANONYMOUS_LOCATION_EVENT_NAME,
  LOCATION_EVENT_NAME,
} from './events/location/constants';
import { groupTypeIdToString } from './utils';

/* eslint-disable-next-line */
const snowplow = ANALYTICS && window.qsnowplow ? window.qsnowplow : null;

const globalHistory = getGlobalHistory ? getGlobalHistory() : false;

let listener = null;
let userData = {};

if (snowplow) {
  snowplow(
    'newTracker',
    TRACKER_SETTING.TRACKER_NAMESPACE,
    config.snowplowCollector,
    {
      appId: TRACKER_SETTING.APP_ID,
      platform: TRACKER_SETTING.PLATFORM,
      stateStorageStrategy: 'none',
      forceSecureTracker: true,
    },
  );
}

const getConfigData = () => {
  const configData = {};
  const { [CONFIG_PROPERTY.SHARD_ID]: shardId } = config;

  if (shardId) {
    try {
      configData[DATA_PROPERTY.SHARD_ID] = parseInt(shardId);
    } catch (error) {
      ErrorReporting.reportError(error);
    }
  }

  return configData;
};

const trackEvent = ({ eventName, properties }) => {
  try {
    snowplow('trackSelfDescribingEvent', {
      event: eventName,
      ...getConfigData(),
      ...userData,
      properties,
    });
  } catch (error) {
    ErrorReporting.reportError(error);
  }
};

const trackLocation = () => {
  const isUserUpdated = !isEmpty(userData);

  trackEvent({
    eventName: isUserUpdated
      ? LOCATION_EVENT_NAME
      : ANONYMOUS_LOCATION_EVENT_NAME,
  });
};

const startLocationTracking = () => {
  if (reactRouterDevToggle) {
    return;
  }

  if (listener) {
    // shut down listener
    listener();
  }

  listener = globalHistory.listen(trackLocation);

  trackLocation();
};

const setUser = (user) => {
  userData = {
    ...userData,
    [DATA_PROPERTY.USER_ID]: user.id,
    [DATA_PROPERTY.APP_LANG]: document.documentElement.lang,
  };
};

const setStaffPortalUserInfo = (userId, shardId) => {
  userData = {
    ...userData,
    [DATA_PROPERTY.USER_ID]: userId,
    [DATA_PROPERTY.SHARD_ID]: shardId,
  };
};

const setGroups = ({ groupId, accountId, groupTypeId }) => {
  userData = {
    ...userData,
    [DATA_PROPERTY.CUSTOMER_ID]: accountId,
    [DATA_PROPERTY.GROUP_ID]: groupId,
  };

  const groupLevel = groupTypeIdToString(groupTypeId);
  if (groupLevel != null) {
    userData[DATA_PROPERTY.GROUP_LEVEL] = groupLevel;
  }
};

const noopWhenDisabled = (func) => (snowplow ? func : () => {});

export default {
  trackLocation: noopWhenDisabled(trackLocation),
  startLocationTracking: noopWhenDisabled(startLocationTracking),
  setUser: noopWhenDisabled(setUser),
  setStaffPortalUserInfo: noopWhenDisabled(setStaffPortalUserInfo),
  setGroups: noopWhenDisabled(setGroups),
  trackEvent: noopWhenDisabled(trackEvent),
};
