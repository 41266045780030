import React from 'react';

const Pencil = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.308 17.6L19.354 7.558a.5.5 0 000-.707L17.15 4.647a.5.5 0 00-.707 0L6.398 14.688l-.103.151-1.752 3.958a.5.5 0 00.66.66l3.954-1.754.15-.104zm-3.326.418l1.19-2.688 7.897-7.895 1.496 1.496-7.898 7.896-2.685 1.19zm11.29-9.794l1.021-1.02-1.496-1.497-1.02 1.02 1.496 1.497z"
      fill="#202020"
    />
  </svg>
);

export default Pencil;
