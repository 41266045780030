import React from 'react';

import PropTypes from 'prop-types';

const Form = ({ onSubmit, children, className }) => {
  return (
    <form onSubmit={onSubmit} noValidate className={className}>
      {children}
    </form>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
