import React, { MouseEvent, ReactNode } from 'react';

import classNames from 'classnames';

import { LegacyStyleButton } from '@shared/components/legacy/LegacyStyleButton';

import './index.scss';

enum TYPES {
  positive = 'text-button-positive',
  negative = 'text-button-negative',
}

interface TextButtonProps {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  label?: ReactNode;
  'data-test-id'?: string;
  disabled?: boolean;
  className?: string;
  id?: string;
  type?: keyof typeof TYPES;
}

const TextButton = React.forwardRef<HTMLButtonElement, TextButtonProps>(
  (
    {
      onClick,
      label,
      'data-test-id': dataTestId = 'textButton',
      children,
      disabled,
      className,
      id,
      type,
    },
    ref,
  ) => {
    return (
      <LegacyStyleButton
        id={id}
        ref={ref}
        disabled={disabled}
        className={classNames(className, 'text-button-default', {
          [type ? TYPES[type] : '']: type,
        })}
        onClick={onClick}
        data-test-id={dataTestId}>
        {children}
        {label}
      </LegacyStyleButton>
    );
  },
);

export default TextButton;
