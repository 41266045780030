import React, { useState } from 'react';

import classNames from 'classnames';

import LegacyDiv from '@shared/components/LegacyDiv';

import useDebounce from '../../../../ManagerPortal/components/hooks/useDebounce';
import Star from '../../../../ManagerPortal/components/Icons/legacy/Star';
import useAppFeedbackModal from '../../../../ManagerPortal/containers/hooks/useAppFeedbackModal';
import { isNil } from '../../../utils';
import Button from '../../ButtonLogin';
import { BUTTON_VARIANT } from '../../ButtonLogin/constants';
import CornerFigure from '../../Icons/CornerFigure';
import LegacyButton from '../../legacy/Button';
import TextButton from '../../TextButton';
import Translate from '../../Translate';

import style from './index.module.scss';

const numberOfStars = [1, 2, 3, 4, 5];
const gTwoLink =
  'https://www.g2.com/contributor/quniyx-vs-visa?secure%5Bpage_id%5D=quniyx-vs-visa&secure%5Brewards%5D=true&secure%5Btoken%5D=0961e154f55c8a8d32329cfa9cdca0e133750c2b9de1cac6fdd722db4d2be637';

const RateApp = ({
  onRateClick,
  onNeverAskAgainClick,
  onDismissNotifyRateApp,
  onSkip,
}) => {
  const [hoveredStars, setHoveredStars] = useState(null);
  const [selectedStars, setSelectedStars] = useState(null);

  const { onOpenModal } = useAppFeedbackModal();

  const debounceRemoveNotification = useDebounce(
    () => onDismissNotifyRateApp(),
    1000,
  );

  const starColor = (currentStarNumber) => {
    if (isNil(hoveredStars)) {
      return currentStarNumber <= selectedStars ? '#3591A0' : '#FFFFFF';
    }

    return currentStarNumber <= hoveredStars ? '#BADDDA' : '#FFFFFF';
  };

  const starRatingText = () => {
    if (!isNil(selectedStars)) {
      return selectedStars <= 3
        ? 'appRating.starRate.low'
        : 'appRating.starRate.high';
    }
    return 'appRating.starRate';
  };

  return (
    <LegacyDiv className="flex-column padding-2-left">
      <LegacyDiv className={style.appRating__title}>
        <Translate>
          {isNil(selectedStars) ? 'appRating.title' : 'appRating.success'}
        </Translate>
      </LegacyDiv>
      {!isNil(selectedStars) && (
        <LegacyDiv className={style.appRating__text}>
          <Translate>appRating.weWillAsk</Translate>
        </LegacyDiv>
      )}
      <LegacyDiv className={style.appRating__body}>
        <LegacyDiv className={style.appRating__text}>
          <Translate>{starRatingText()}</Translate>
        </LegacyDiv>
        {selectedStars <= 3 && (
          <LegacyDiv
            className={style.appRating__feedback}
            data-test-id="sendUsFeedbackLink"
            onClick={onOpenModal}>
            &nbsp;
            <Translate>appRating.feedback</Translate>
          </LegacyDiv>
        )}
      </LegacyDiv>
      {isNil(selectedStars) && (
        <LegacyDiv className="flex-row">
          {numberOfStars.map((currentStarNumber) => (
            <LegacyDiv
              onMouseEnter={() => setHoveredStars(currentStarNumber)}
              onMouseLeave={() => setHoveredStars(null)}
              key={currentStarNumber}>
              <LegacyButton
                imageButton
                onClick={() => {
                  onRateClick(currentStarNumber);
                  setSelectedStars(currentStarNumber);
                  if (currentStarNumber <= 3) {
                    debounceRemoveNotification();
                  }
                }}
                className="padding-1">
                <Star fill={starColor(currentStarNumber)} />
              </LegacyButton>
            </LegacyDiv>
          ))}
        </LegacyDiv>
      )}
      {isNil(selectedStars) && (
        <>
          <LegacyDiv className="flex-row" data-test-id="appRatingAskMeAgain">
            <TextButton
              className={style.appRating__linkText}
              onClick={() => {
                onNeverAskAgainClick();
                onDismissNotifyRateApp();
              }}>
              <Translate>appRating.askMeAgain</Translate>
            </TextButton>
          </LegacyDiv>
          <LegacyDiv className="flex-row" data-test-id="appRatingRemindMeNext">
            <TextButton
              className={style.appRating__linkText}
              onClick={() => {
                onSkip();
                onDismissNotifyRateApp();
              }}>
              <Translate>appRating.remindMeNext</Translate>
            </TextButton>
          </LegacyDiv>
        </>
      )}
      {selectedStars >= 4 && (
        <LegacyDiv className="flex-row flex-end padding-1-top">
          <Button
            data-test-id="cancel-g2-review"
            variant={BUTTON_VARIANT.SECONDARY}
            text="label.noThanks"
            onClick={() => debounceRemoveNotification()}
          />
          <Button
            data-test-id="open-g2-review"
            variant={BUTTON_VARIANT.PRIMARY}
            text="label.yes"
            onClick={() => window.open(gTwoLink, '_blank')}
          />
        </LegacyDiv>
      )}
      <LegacyDiv
        className={classNames(style.appRating__cornerFigure, {
          'opacity-0': !isNil(selectedStars),
        })}>
        <CornerFigure />
      </LegacyDiv>
    </LegacyDiv>
  );
};

export default RateApp;
