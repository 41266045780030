import React from 'react';

import { Router } from '@reach/router';

import PasswordLogin from '../PasswordLogin';
import AuthCodeLogin from './AuthCodeLogin';

const MFA = ({
  onResetLoginProviders,
  onManagerPortalLogin,
  onStaffPortalLogin,
  email,
  region,
  location: { search },
  domainGroupId,
}) => (
  <Router>
    <PasswordLogin
      path="/*"
      onResetLoginProviders={onResetLoginProviders}
      onManagerPortalLogin={onManagerPortalLogin}
      onStaffPortalLogin={onStaffPortalLogin}
      email={email}
      region={region}
      domainGroupId={domainGroupId}
      isMFA
    />
    <AuthCodeLogin
      path="/authcode/*"
      search={search}
      onManagerPortalLogin={onManagerPortalLogin}
      onStaffPortalLogin={onStaffPortalLogin}
    />
  </Router>
);

export default MFA;
