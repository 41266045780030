import React, { FC, useState } from 'react';

import classNames from 'classnames';
import { string } from 'prop-types';

import LegacyDiv from '@shared/components/LegacyDiv';

import useDelay from '../hooks/useDelay';
import LoadingIcon from '../Icons/LoadingIcon';

interface LoadingProps {
  className?: string;
  fill?: string;
  delay?: number;
  'data-test-id'?: string;
}

const Loading: FC<LoadingProps> = ({
  className,
  fill = '#0e75c2',
  delay = 1000,
  'data-test-id': dataTestId,
}) => {
  const [show, setShow] = useState(false);
  useDelay(() => setShow(true), delay);
  if (delay && !show) return null;

  return (
    <LegacyDiv
      className={classNames('spinner', className)}
      data-test-id={dataTestId}>
      <LoadingIcon fill={fill} />
    </LegacyDiv>
  );
};

Loading.propTypes = {
  /**
   * Color to fill loading spinner with, can be either in hex format (e.g. '#0e75c2') or text (e.g. 'red').
   */
  fill: string,
  className: string,
};

export default Loading;
