import { useContext } from 'react';

import { LocationContext } from '@shared/components/Context';
import { assertContextHasValue } from '@shared/utils/assertContextAvailable';

const useLocation = () => {
  const value = useContext(LocationContext);
  assertContextHasValue(value, 'useLocation');
  return value;
};

export default useLocation;
