import React from 'react';

const ArrowDown = ({ height = 8, width = 16, fill = '#30363E', ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 16 8" fill="none" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.646447 0.146447C0.841709 -0.0488155 1.15829 -0.0488155 1.35355 0.146447L8 6.79289L14.6464 0.146447C14.8417 -0.0488155 15.1583 -0.0488155 15.3536 0.146447C15.5488 0.341709 15.5488 0.658291 15.3536 0.853553L8.35355 7.85355C8.15829 8.04882 7.84171 8.04882 7.64645 7.85355L0.646447 0.853553C0.451184 0.658291 0.451184 0.341709 0.646447 0.146447Z"
      fill={fill}
    />
  </svg>
);

export default ArrowDown;
