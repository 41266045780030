import React from 'react';

const ArrowLeft = (props) => (
  <svg
    width={props.width || '16px'}
    height={props.height || '16px'}
    viewBox="0 0 16 16"
    {...props}>
    <path
      d="M9.59 1.005a1 1 0 1 1 1.415 1.414L6.76 6.664a.996.996 0 0 0-.001 1.42l4.24 4.24a1 1 0 1 1-1.414 1.414L4.634 8.787a2 2 0 0 1 0-2.826l4.957-4.956z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowLeft;
