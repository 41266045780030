import React, { useState } from 'react';

import classNames from 'classnames';

import { Input } from '@shared/components/FormElements';
import useTranslate from '@shared/components/hooks/useTranslate';
import EyeIcon from '@shared/components/Icons/Eye';
import LegacyDiv from '@shared/components/LegacyDiv';

import styles from './index.module.scss';

interface PasswordInputProps {
  name: string;
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  name,
  label,
  onChange,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const translate = useTranslate();

  return (
    <LegacyDiv className={styles.passwordInputWrapper}>
      <Input
        id={name}
        name={name}
        type={showPassword ? 'text' : 'password'}
        label={translate(label)}
        data-test-id={name}
        onChange={onChange}
        {...rest}
      />
      <EyeIcon
        data-test-id="eye-icon"
        onClick={() => setShowPassword((show: boolean) => !show)}
        height={38}
        width={38}
        className={classNames(
          showPassword
            ? styles.passwordInputIcon
            : `${styles.passwordInputIcon} ${styles.passwordInputIconDisabled}`,
        )}
      />
    </LegacyDiv>
  );
};
export default PasswordInput;
