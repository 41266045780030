/* eslint-disable valid-jsdoc */
import { useCallback, useRef } from 'react';

import {
  // eslint-disable-next-line no-restricted-imports
  useMatch as useMatchOriginal,
  // eslint-disable-next-line no-restricted-imports
  useNavigate,
} from '@reach/router';
import { parse, ParsedQuery, stringify, StringifyOptions } from 'query-string';

import config from '@shared/config';

import useLocation from '@ManagerPortal/components/hooks/useLocation';

import { isNil } from '..';

// eslint-disable-next-line no-restricted-imports
export { useNavigate } from '@reach/router';
export type { ParsedQuery } from 'query-string';

/**
 * @deprecated Use hook useNavigate instead. Global navigate does not work well in tests
 */
export { navigate } from '@shared/utils/route/wrappers/BrowserLocationProvider';

export const useMatch = <T extends string>(
  path: string,
): Record<T | 'uri' | 'path', string> | null => {
  // @ts-expect-error we just correct it to work with the generic
  return useMatchOriginal(path);
};

export const parseQueryString = (query?: string): ParsedQuery<string> => {
  if (!query) return {};
  // avoid XSS attacks. Example javascript:alert('hello')
  return query.includes('javascript')
    ? {}
    : parse(query, {
        arrayFormat: 'bracket',
      });
};

export const stringifyQuery = (query: Record<string, unknown>, options = {}) =>
  stringify(query, {
    arrayFormat: 'bracket',
    encode: false,
    ...options,
  });

export const stringifyUrl = (
  pathname: string,
  query = {},
  options?: StringifyOptions,
) => {
  if (Object.keys(query).length === 0) {
    return pathname;
  }
  return `${pathname}?${stringifyQuery(query, options)}`;
};

export const stripTrailingSlash = (path = '') => path.replace(/\/$/, '');

export const useUrlParamsPreservation = () => {
  const { query } = useLocation();

  return useCallback(
    (to: string) => {
      const [path, search] = to.split('?');
      const parsedSearch = parseQueryString(search);

      if (!path) throw new Error('Path is required');

      return stringifyUrl(path, { ...query, ...parsedSearch });
    },
    [query],
  );
};

export const useNavigatePreservingParams = () => {
  const navigateFunc = useNavigate();
  const location = useLocation();
  const locationRef = useRef(location);
  locationRef.current = location;
  return useCallback(
    (to: string) => {
      const oldSearch = locationRef.current.query;

      const [path, newSearchString] = to.split('?');
      const newSearch = parseQueryString(newSearchString);

      if (isNil(path)) throw new Error('Path is required');

      return navigateFunc(stringifyUrl(path, { ...oldSearch, ...newSearch }));
    },
    [navigateFunc],
  );
};

export const generateTaskManagementUrl = () =>
  `${config.new_api}/v1/security/taskmanagement/redirectuser`;
