import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';

import { render } from 'react-dom';

import { fetchConfiguration } from './shared/api/configuration';

import './index.scss';

import store from './ManagerPortal/redux/store';
import Analytics from './ManagerPortal/utils/analytics';
import { updateConfig } from './shared/config/utils';
import AppProvider from './shared/containers/AppProvider';
import ErrorReporting from './shared/utils/errorreporting';

if (__DEV__) {
  window.store = store;
}

const initialise = () => {
  ErrorReporting.init();
  Analytics.startLocationTracking();
  render(<AppProvider />, document.getElementById('app-container'));
};

fetchConfiguration()
  .then(({ body }) => {
    updateConfig(body);
    initialise();
  })
  .catch(() => initialise());
