import React from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';

import QlogoAndName from '../../../components/Icons/QlogoAndName';

const Fallback = () => (
  <LegacyDiv className="position-absolute">
    <QlogoAndName
      width="133px"
      height="35px"
      className="init-loader-logo"
      innerClass="init-loader-logo-inner"
    />
  </LegacyDiv>
);

export default Fallback;
