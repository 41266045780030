import React from 'react';

import { CaptionProps, useNavigation } from 'react-day-picker';

import { Select } from '@shared/components/FormElements/DropdownInputs/Select';
import useTranslate from '@shared/components/hooks/useTranslate';
import ArrowLeft from '@shared/components/Icons/ArrowLeft';
import ArrowRight from '@shared/components/Icons/ArrowRight';
import LegacyDiv from '@shared/components/LegacyDiv';
import { monthsList } from '@shared/utils/time';

import { TYearOptionsType } from './helpers';

import s from './ReactDayPicker.module.scss';

interface ICustomCaptionProps extends CaptionProps {
  yearOptions: TYearOptionsType;
}

export const CustomCaption = ({ yearOptions }: ICustomCaptionProps) => {
  const { goToMonth, currentMonth, nextMonth, previousMonth } = useNavigation();
  const translate = useTranslate();

  const monthOptions = monthsList().map((monthLabel, monthValue) => ({
    label: translate(monthLabel),
    value: monthValue,
  }));

  // todo - we can't use our date utils here since the locale of
  // date picker relies on date-fns locales and not compatible with moment
  // locales. This is a temporary solution until we can fully remove moment
  // and unify the date formatting across the app
  const currentMonthKey = currentMonth.getMonth();
  const currentYearKey = currentMonth.getFullYear();

  return (
    <LegacyDiv className={s.captionWrapper}>
      <LegacyDiv className={s.arrowLeft}>
        <ArrowLeft onClick={() => previousMonth && goToMonth(previousMonth)} />
      </LegacyDiv>
      <LegacyDiv className={s.monthAndYearWrap}>
        <LegacyDiv className={s.selectWrap}>
          <Select
            dataTestId="datepicker-input-month-select"
            isClearable={false}
            value={currentMonthKey}
            maxMenuHeight={220}
            options={monthOptions}
            onChange={(selectedMonth) => {
              goToMonth(new Date(currentYearKey, selectedMonth || 0, 1));
            }}
          />
        </LegacyDiv>

        <LegacyDiv className={s.selectWrap}>
          <Select
            dataTestId="datepicker-input-year-select"
            isClearable={false}
            value={currentYearKey}
            maxMenuHeight={220}
            options={yearOptions}
            onChange={(selectedYear: number | null | undefined) => {
              goToMonth(
                new Date(
                  selectedYear || new Date().getFullYear(),
                  currentMonthKey,
                  1,
                ),
              );
            }}
          />
        </LegacyDiv>
      </LegacyDiv>
      <LegacyDiv className={s.arrowRight}>
        <ArrowRight onClick={() => nextMonth && goToMonth(nextMonth)} />
      </LegacyDiv>
    </LegacyDiv>
  );
};

export const withPropsCustomCaption = (
  Component: React.ComponentType<ICustomCaptionProps>,
  yearOptions: TYearOptionsType,
) => {
  return (props: CaptionProps) => {
    return <Component {...props} yearOptions={yearOptions} />;
  };
};
