import { useCallback, useState } from 'react';

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState((prevState) => !prevState), []);
  const check = useCallback(() => setState(true), []);
  const uncheck = useCallback(() => setState(false), []);

  return [state, { toggle, check, uncheck }] as const;
};

export default useToggle;
