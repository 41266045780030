import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import Button, { ButtonProps } from '../legacy/Button';
import Translate from '../Translate';

import './index.scss';

const isTestEnv = process.env.NODE_ENV === 'test';
const isDevEnv = process.env.NODE_ENV === 'development';

// NOTE: this is used to set root element for ReactModal to support screenreaders.
// It will be disabled for test and dev environment, since tests and storybook render differently.
if (!isTestEnv && !isDevEnv) {
  ReactModal.setAppElement('#app-container');
}

interface ModalProps extends ReactModal.Props {
  modalOverlayClass?: string;
}

const Modal = (props: ModalProps) => {
  const { modalOverlayClass = '', className } = props;

  return (
    <ReactModal
      {...props}
      className={classNames(
        'react-modal',
        typeof className === 'object' ? { ...className } : className,
      )}
      overlayClassName={`react-modal-overlay ${modalOverlayClass}`}
      ariaHideApp={!isDevEnv} // disabling warning about root element for dev environment, on production it should work fine
    />
  );
};

Modal.propTypes = {
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  modalOverlayClass: PropTypes.string,
};

interface ModalTitleProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  children: string;
}

interface ModalBodyProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  > {
  children: string;
}

const ModalTitle = ({ children, ...props }: ModalTitleProps) => (
  <h3 {...props} className="sharedModal_title">
    <Translate>{children}</Translate>
  </h3>
);
const ModalBody = ({ children, ...props }: ModalBodyProps) => (
  <h3 {...props} className="sharedModal_body">
    <Translate>{children}</Translate>
  </h3>
);

interface ModalActionProps extends ButtonProps {
  children: string;
  variant?: 'primary' | 'secondary';
}

const ModalAction = ({
  children,
  variant = 'primary',
  ...props
}: ModalActionProps) => {
  const buttonProps = {
    primary: variant === 'primary',
    secondary: variant === 'secondary',
  };
  return (
    <Button {...props} {...buttonProps}>
      <Translate>{children}</Translate>
    </Button>
  );
};

interface ModalActionsProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode;
}

const ModalActions: FC<ModalActionsProps> = (props) => (
  <div {...props} className="sharedModal_actions" />
);

export { ModalTitle, ModalActions, ModalAction, ModalBody };
export default Modal;
