import { stringifyUrl } from '@shared/utils/route';

import { del, get, post, put } from '..';

export const fetchProviderRegistrations = (groupId) =>
  get(`/v1/mfaprovider/providerregistration/groups/${groupId}`);

export const enableMFAProvider = (groupId) =>
  post(`/v1/mfaprovider/providerregistration/groups/${groupId}`);

export const deleteProviderRegistration = (groupId, registrationId) =>
  del(
    `/v1/mfaprovider/providerregistration/groups/${groupId}/registrations/${registrationId}`,
  );

export const fetchPasswordDisabledEndpoint = (domainGroupId) =>
  get(`/v1/settings/password-web/domain-group/${domainGroupId}/is-disabled`);

export const setPasswordDisabledEndpoint = (
  domainGroupId,
  isPasswordWebDisabled,
) =>
  put(`/v1/settings/password-web/domain-group/${domainGroupId}`, {
    isPasswordWebDisabled,
  });

export const fetchMFASettings = (groupId) =>
  get(`/v1/mfaprovider/mfasetting/groups/${groupId}`);

export const updateMFASettings = (groupId, gracePeriodHour) =>
  put(`/v1/mfaprovider/mfasetting`, {
    groupId,
    gracePeriodHour,
  });

export const fetchPasswordEntropy = (password) =>
  post(`/v1/mfaprovider/password-entropy`, { password });

export const fetchCustomerPasswordEntropy = (token) =>
  get(stringifyUrl(`/v1/mfaprovider/password-entropy`, { token }));

export const fetchCustomerPasswordEntropyPerEmployee = (
  domainGroupId,
  employeeId,
) =>
  get(
    `/v1/mfaprovider/password-entropy/groups/${domainGroupId}/employees/${employeeId}`,
  );

export const validateUpdatepasswordHash = (hash) =>
  get(stringifyUrl(`/v1/security/reset/validate`, { hash }));

export const fetchAdvancedPasswordSettings = (domainGroupId) =>
  get(`/v1/mfaprovider/groups/${domainGroupId}/password-setting`);

export const updateAdvancedPasswordSettings = ({
  domainGroupId,
  managerPasswordEntropy,
  staffPasswordEntropy,
  managerPasswordLifetimeInDays,
  staffPasswordLifetimeInDays,
}) =>
  put(`/v1/mfaprovider/groups/${domainGroupId}/password-setting`, {
    managerPasswordEntropy,
    staffPasswordEntropy,
    managerPasswordLifetimeInDays,
    staffPasswordLifetimeInDays,
  });

export const updateAdvancedPassword = (password, token) =>
  post(`/v1/mfaprovider/password/resetPassword`, { password, token });
