import React, { CSSProperties, forwardRef, ReactElement } from 'react';

import classNames from 'classnames';
import t from 'prop-types';
// @ts-expect-error Version of `react-select` we currently use doesn't have ts declarations
import ReactSelect from 'react-select';

import { colors } from '@shared/colors';
import useTranslate from '@shared/components/hooks/useTranslate';
import LegacyDiv from '@shared/components/LegacyDiv';

import { withFormControl, WithFormControlProps } from '../../Form';
import Label from '../../Label';
import { SelectOption } from '../types';
import { Action, SelectProps, Value } from './types';

import s from './index.module.scss';

const findByValue = <M, V>(
  value: Value<M, V>,
  options: SelectOption<V>[],
  isMulti: M,
) => {
  const values = (Array.isArray(value) ? value : [value]) as V[];

  const searchFn = (option: SelectOption<V>) => values.includes(option.value);
  if (isMulti) return options.filter(searchFn) as SelectOption<V>[];
  return options.find(searchFn) as SelectOption<V> | undefined;
};

const SelectRenderFunc = <V extends string | number, M extends boolean = false>(
  {
    label,
    required,
    value,
    error,
    info,
    placeholder = 'Select',
    options,
    onChange,
    id = crypto.randomUUID(),
    className,
    isMulti: _isMulti,
    disabled,
    readOnly,
    children,
    dataTestId,
    ...props
  }: SelectProps<M, V>,
  ref: unknown,
) => {
  // we create `isMulti` variable instead of defaulting it in props destructuring cause of incorrect TS compiling for default here
  const isMulti = _isMulti || false;
  const translate = useTranslate();
  const translatedOptions = options.map((option) => ({
    ...option,
    label: translate(option.label),
    value: option.value,
  }));

  const fixedOptionsValue = options
    .filter((option) => option.isFixed)
    .map((option) => option.value);

  // TODO: tidy up
  const handleChange = (
    selection: (M extends true ? SelectOption<V>[] : SelectOption<V>) | null,
    actionMeta: Action<V>,
  ) => {
    if (actionMeta.removedValue && actionMeta.removedValue.isFixed) {
      return;
    }

    if (isMulti) {
      return (onChange as (change: V[]) => void)(
        Array.isArray(selection)
          ? selection.map((eachSelected) => eachSelected.value)
          : [...fixedOptionsValue],
      );
    }

    return (onChange as (change: V | null | undefined) => void)(
      (selection as SelectOption<V>)?.value ?? null,
    );
  };

  const selectedOption = findByValue(value, translatedOptions, isMulti);

  const isClearable =
    !isMulti ||
    !Array.isArray(value) ||
    value.some((optionValue) => !fixedOptionsValue.includes(optionValue));

  // We use this styles when we have fixed options
  const styles = {
    multiValue: (
      base: CSSProperties,
      state: {
        data: SelectOption<V>;
        // there are much more properties - need to be typed later
      },
    ) =>
      state.data.isFixed
        ? {
            ...base,
            backgroundColor: `${colors.gray_05} !important`,
            paddingRight: '0 !important',
          }
        : base,
    multiValueRemove: (base: CSSProperties, state: { data: SelectOption<V> }) =>
      state.data.isFixed ? { ...base, display: 'none' } : base,
    option: (
      base: CSSProperties,
      state: { data: { underlined?: boolean } },
    ) => ({
      ...base,
      borderBottom: state.data.underlined && `1px solid ${colors.gray_05}`,
    }),
  };

  return (
    <LegacyDiv
      className={classNames(s.selectWrap, className)}
      data-test-id={dataTestId}>
      {label && <Label htmlFor={id} label={label} required={required} />}

      <ReactSelect
        isClearable={isClearable}
        {...props}
        ref={ref}
        inputId={id}
        isMulti={isMulti}
        isDisabled={disabled || readOnly}
        classNamePrefix="custom-select"
        placeholder={translate(placeholder)}
        value={selectedOption}
        onChange={handleChange}
        options={translatedOptions}
        styles={styles}
      />

      {error && <span className={s.errorMsg}>{translate(error)}</span>}

      {info && <span className={s.infoMsg}>{translate(info)}</span>}
    </LegacyDiv>
  );
};

export const Select = forwardRef(SelectRenderFunc) as <
  V extends string | number,
  M extends boolean = false,
>(
  // eslint "no-use-before-define" below needed to hack passing generics to render func of forwardRef
  // eslint-disable-next-line no-use-before-define
  props: SelectProps<M, V> & { ref?: unknown },
) => ReactElement;

// @ts-expect-error we need this exception for `displayName` because we assert type instead of `forwardedRef` to enable generics passing
Select.displayName = 'Select';
// @ts-expect-error we need this exception for `propTypes` because we assert type instead of `forwardedRef` to enable generics passing
Select.propTypes = {
  closeMenuOnSelect: t.bool,
  dataTestId: t.string,
  disabled: t.bool,
  error: t.string,
  info: t.string,
  isClearable: t.bool,
  isLoading: t.bool,
  isMulti: t.bool,
  label: t.string,
  placeholder: t.string,
  readOnly: t.bool,
  required: t.oneOfType([t.bool, t.string]),
};

export function FormSelect<
  V extends string | number,
  M extends boolean = false,
>(props: WithFormControlProps<SelectProps<M, V>, Value<M, V>>) {
  return withFormControl(Select<V, M>)(props);
}
