import React, { FC, FormHTMLAttributes } from 'react';

import type {
  ControllerRenderProps,
  InternalFieldName,
  Message,
  SubmitHandler,
  UseFormRegisterReturn,
  Validate,
  ValidationRule,
  ValidationValue,
  ValidationValueMessage,
} from 'react-hook-form';
import {
  Controller,
  DeepPartial,
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';

import { Button, ButtonProps, LoadingButton } from '@shared/components/Button';
import { StrictOmit } from '@shared/types';
import { callAll } from '@shared/utils';

import { FormSubmitHandler } from './types';

export {
  useForm,
  useFieldArray,
  useWatch,
  useFormContext,
} from 'react-hook-form';

type FormProps<T extends Record<string, unknown>> = StrictOmit<
  FormHTMLAttributes<HTMLFormElement>,
  'onSubmit'
> & {
  onSubmit: FormSubmitHandler<T>;
  methods?: UseFormReturn<T, object>;
  defaultValues?: DeepPartial<T>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Form = <T extends Record<string, any>>({
  children,
  defaultValues,
  methods: controlledMethods,
  onSubmit,
  className: _className,
  ...props
}: FormProps<T>) => {
  const uncontrolledMethods = useForm({ defaultValues, mode: 'onChange' });
  const methods = controlledMethods || uncontrolledMethods;

  const handleSubmitWithMethods = (
    ...standardParams: Parameters<SubmitHandler<T>>
  ) => onSubmit(...standardParams, methods);

  return (
    <FormProvider {...methods}>
      <form
        {...props}
        className="display-contents"
        onSubmit={methods.handleSubmit(handleSubmitWithMethods)}>
        {children}
      </form>
    </FormProvider>
  );
};

const path = (paths: string[], obj: object): string | undefined => {
  return paths.reduce((value: any, name) => {
    const parsedName = name.match(/\[(\d+)]/)?.[1] ?? name;
    return value?.[parsedName];
  }, obj);
};

const defaultValidationMessages = {
  required: 'errorMessage.thisFieldIsRequired',
  min: 'errorMessage.lessThanMinimum',
  max: 'errorMessage.moreThanMaximum',
  minLength: 'errorMessage.NumberOfCharactersLessThanMinimum',
  maxLength: 'errorMessage.NumberOfCharactersMoreThanMaximum',
  pattern: '',
};

interface Rules<T> {
  required?: Message | ValidationRule<boolean>;
  min?: ValidationRule<number | string>;
  max?: ValidationRule<number | string>;
  maxLength?: ValidationRule<number>;
  minLength?: ValidationRule<number>;
  pattern?: ValidationRule<RegExp>;
  validate?: Validate<T, unknown>;
}

const withValidationMessage = <T,>(rules: Rules<T>): Rules<T> => {
  return Object.entries(rules).reduce((acc, [key, rule]) => {
    if (
      rule?.message ||
      key === 'validate' ||
      (key === 'required' && typeof rule === 'string')
    ) {
      acc[key] = rule;
    } else {
      acc[key] = {
        value: rule as ValidationValue,
        message:
          defaultValidationMessages[
            key as keyof StrictOmit<Rules<T>, 'validate'>
          ],
      };
    }

    return acc;
  }, {} as Record<string, ValidationValueMessage | ValidationRule>);
};

export interface WithFormWrapperProps<T> extends Rules<T> {
  name: string;
  disabled?: boolean;
  deps?: InternalFieldName | InternalFieldName[];
}

export const withForm = <
  ComponentPropsType extends Record<string, any>,
  ValueType extends ComponentPropsType['value'],
>(
  Component: FC<ComponentPropsType>,
) => {
  const Wrapper: FC<
    WithFormWrapperProps<ValueType> &
      StrictOmit<
        ComponentPropsType,
        keyof WithFormWrapperProps<ValueType> | keyof UseFormRegisterReturn
      > &
      Partial<Pick<ComponentPropsType, 'onChange' | 'onBlur'>>
  > = ({
    name,
    onChange,
    onBlur,
    disabled,
    required,
    maxLength,
    minLength,
    max,
    min,
    pattern,
    // useForm().register embellishments
    deps,
    validate,
    ...props
  }) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    const field = register(name, {
      deps,
      onBlur,
      onChange,
      disabled,
      ...withValidationMessage({
        required,
        maxLength,
        minLength,
        max,
        min,
        pattern,
        validate,
      }),
    });
    return (
      // @ts-expect-error since we exclude some props from ComponentPropsType (keyof WithFormWrapperProps )
      // TS can not be sure about correctness of the props passed to the Component
      <Component
        {...props}
        {...field}
        // we need this one for styling...
        required={Boolean(required)}
        error={path([...name.split('.'), 'message'], errors)}
      />
    );
  };
  Wrapper.displayName = `withForm(${Component.displayName})`;
  return Wrapper;
};

export interface WithFormControlWrapperProps<T> extends Rules<T> {
  name: string;
  defaultValue?: T;
}

export type WithFormControlProps<
  ComponentPropsType extends Record<string, any>,
  ValueType extends ComponentPropsType['value'],
> = WithFormControlWrapperProps<ValueType> &
  StrictOmit<
    ComponentPropsType,
    keyof WithFormControlWrapperProps<ValueType> | keyof ControllerRenderProps
  > &
  Partial<Pick<ComponentPropsType, 'onChange'>>;

export const withFormControl = <
  ComponentPropsType extends Record<string, any>,
  ValueType extends ComponentPropsType['value'],
>(
  Component: FC<ComponentPropsType>,
) => {
  const Wrapper: FC<WithFormControlProps<ComponentPropsType, ValueType>> = ({
    name,
    onChange,
    required,
    min,
    max,
    validate,
    defaultValue,
    ...props
  }) => {
    return (
      <Controller
        name={name}
        defaultValue={defaultValue}
        rules={withValidationMessage({ required, min, max, validate })}
        render={({ field, formState: { errors } }) => {
          return (
            // @ts-expect-error since we exclude some props from ComponentPropsType (keyof WithFormControlWrapperProps )
            // TS can not be sure about correctness of the props passed to the Component
            <Component
              {...props}
              {...field}
              ref={field.ref}
              name={name}
              required={required}
              onChange={callAll(field.onChange, onChange)}
              error={path(
                [...name.split(/\.|(\[\d+])/), 'message'].filter(Boolean),
                errors,
              )}
            />
          );
        }}
      />
    );
  };
  Wrapper.displayName = `withFormControl(${Component.displayName})`;
  return Wrapper;
};

type SubmitButtonProps = StrictOmit<
  ButtonProps<'button'>,
  'disabled' | 'type' | 'text' | 'data-test-id'
> & {
  disabled?: boolean;
  text?: string;
  isLoading?: boolean;
  'data-test-id'?: string;
};
export const SubmitButton: FC<SubmitButtonProps> = ({
  text = 'Save',
  disabled,
  isLoading, // Pass as prop in case status is "not" / "not only" controlled by form
  'data-test-id': dataTestId = 'submitButton',
  ...props
}) => {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext();

  return (
    <>
      {isLoading || isSubmitting ? (
        <LoadingButton
          text={text}
          disabled={!isValid || disabled || isLoading}
          isLoading={isLoading}
          {...props}
          data-test-id={dataTestId}
        />
      ) : (
        <Button
          text={text}
          type="submit"
          disabled={!isValid || disabled}
          {...props}
          data-test-id={dataTestId}
        />
      )}
    </>
  );
};

type CancelButtonProps = StrictOmit<
  ButtonProps<'button'>,
  'disabled' | 'type' | 'text' | 'data-test-id'
> & {
  text?: string;
  'data-test-id'?: string;
};
export const CancelButton: FC<CancelButtonProps> = ({
  text = 'Cancel',
  'data-test-id': dataTestId = 'cancelButton',
  ...props
}) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Button
      text={text}
      color="neutral"
      {...props}
      type="reset"
      variant="text"
      disabled={isSubmitting}
      data-test-id={dataTestId}
    />
  );
};
