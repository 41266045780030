const PROPERTY = Object.freeze({
  VIEW: 'view',
  DAY_RANGE: 'dayrange',
  DATE_PERIOD: 'datePeriod',
  FROM_DATE: 'from_date',
  TO_DATE: 'to_date',
});

const VIEW = Object.freeze({
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  CUSTOM: 'custom',
});

export { PROPERTY, VIEW };
