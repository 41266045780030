import React, { useState } from 'react';

import AuthPageWrapper from '@shared/components/AuthPageWrapper';
import Button from '@shared/components/ButtonLogin';
import Feedback from '@shared/components/Feedback';
import Form from '@shared/components/Form';
import FormControl from '@shared/components/Form/FormControl';
import FormGroup from '@shared/components/Form/FormGroup';
import Label from '@shared/components/Form/Label';
import LegacyDiv from '@shared/components/LegacyDiv';
import Link from '@shared/components/Link';
import Translate from '@shared/components/Translate';
import { useNavigate } from '@shared/utils/route';

import './index.scss';

import { BUTTON_VARIANT } from '@shared/components/ButtonLogin/constants';
import Pencil from '@shared/components/Icons/Pencil';
import {
  LOGIN_ERROR_MESSAGE as ERROR_MESSAGE,
  PASSWORD,
} from '@shared/constants';
import { RouteComponentProps } from '@shared/types';

const e164Regex = /^\+[1-9]\d{1,14}$/;

type PasswordLoginProps = RouteComponentProps & {
  email: string;
  region?: string;
  password: string;
  phoneNumber: string | null;
  errors: {
    showValidations?: boolean;
    emailError?: string;
    passwordError?: string;
    errorsFeedbackOpen?: boolean;
    errorsFeedbackMessage?: string;
    areUserCredentialsExpired?: boolean;
  };
  resetPasswordUrl: string;
  passwordSecurityLevel: string | number;
  passwordResetToken: string | null;
  isLoading: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: SubmitEvent) => Promise<unknown>;
  onResetLoginProviders: () => void;
  domainGroupId?: string;
};

const PasswordLogin = ({
  email,
  region,
  password,
  phoneNumber,
  resetPasswordUrl,
  passwordSecurityLevel,
  passwordResetToken,
  errors: {
    emailError,
    passwordError,
    errorsFeedbackOpen,
    errorsFeedbackMessage,
    areUserCredentialsExpired,
    showValidations,
  } = {},
  isLoading,
  onChange,
  onSubmit,
  onResetLoginProviders,
  domainGroupId,
}: PasswordLoginProps) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate('/');
  };

  const noPhoneNumber = errorsFeedbackMessage === ERROR_MESSAGE.NO_PHONE_NUMBER;
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value && e164Regex.test(e.target.value)) {
      setError(null);
    } else {
      setError(
        'Phone number must be in the format [+][country code, 1-3 digits, cannot start with 0][subscriber number]',
      );
    }
    onChange(e);
  };
  return (
    <AuthPageWrapper>
      {!noPhoneNumber && (
        <LegacyDiv className="flex-one">
          <LegacyDiv className="flex-center margin-4-bottom">
            <h1 className="login-header padding-0">Almost there!</h1>
          </LegacyDiv>
          <Form
            className="flex flex-one flex-column flex-space-between"
            onSubmit={onSubmit}>
            <LegacyDiv>
              <FormGroup
                data-test-id="emailFormGroup"
                className="flex flex-row flex-items-center"
                feedback={
                  showValidations ? (
                    <span className="font__xSmallRegular color-warning">
                      {emailError}
                    </span>
                  ) : null
                }>
                <LegacyDiv className="flex-one">
                  <Label
                    htmlFor="email"
                    className="font__xSmallRegular margin-0">
                    Email entered
                  </Label>
                  {/* @ts-expect-error can be removed when FormControl is written in ts "Property 'focusRef' is missing in type" */}
                  <FormControl
                    name="email"
                    type="email"
                    value={email}
                    disabled
                    className="padding-0 flex-one border-none"
                    data-test-id="emailInput"
                  />
                </LegacyDiv>
                <LegacyDiv className="pointer padding-1-right">
                  <Pencil onClick={onResetLoginProviders} />
                </LegacyDiv>
              </FormGroup>

              <FormGroup
                data-test-id="regionFormGroup"
                className="flex flex-row flex-items-center">
                <LegacyDiv className="flex-one">
                  <Label
                    htmlFor="email"
                    className="font__xSmallRegular margin-0">
                    Region entered
                  </Label>
                  {/* @ts-expect-error can be removed when FormControl is written in ts "Property 'focusRef' is missing in type" */}
                  <FormControl
                    name="region"
                    type="region"
                    value={region}
                    disabled
                    className="padding-0 flex-one border-none"
                    data-test-id="regionInput"
                  />
                </LegacyDiv>
                <LegacyDiv className="pointer padding-1-right">
                  <Pencil onClick={onResetLoginProviders} />
                </LegacyDiv>
              </FormGroup>

              <FormGroup
                data-test-id="passwordFormGroup"
                className="login__passwordInput"
                feedback={
                  showValidations ? (
                    <span className="font__xSmallRegular color-warning">
                      {passwordError}
                    </span>
                  ) : null
                }>
                <Label htmlFor="password">Password</Label>
                <FormControl
                  name="password"
                  type="password"
                  focusRef={(input: HTMLInputElement) => input?.focus?.()}
                  value={password}
                  onChange={onChange}
                  data-test-id="passwordInput"
                  maxLength={PASSWORD.MAX_LENGTH}
                />
              </FormGroup>
              <Feedback
                className="font__xSmallRegular color-warning"
                open={errorsFeedbackOpen}>
                <Translate>{errorsFeedbackMessage}</Translate>
              </Feedback>
              <Feedback
                open={areUserCredentialsExpired}
                className="padding-0 margin-5-top padding-2-top padding-4-bottom text-right font__xSmallRegular color-warning">
                <p className="margin-0 padding-0">
                  {ERROR_MESSAGE.EXPIRED_CREDENTIALS}
                </p>
                <Link
                  to="/setnewpassword"
                  data-test-id="setNewPasswordLink"
                  className="set-new-password-link bold"
                  state={{
                    passwordSecurityLevel,
                    passwordResetToken,
                  }}>
                  Please click here to set a new password.
                </Link>
              </Feedback>
            </LegacyDiv>
            <Button
              text="Log in"
              type="submit"
              data-test-id="logInButton"
              shouldGrow
              variant={BUTTON_VARIANT.LOGIN}
              isLoading={isLoading}
              isDisabled={!password}
            />
          </Form>
        </LegacyDiv>
      )}
      {noPhoneNumber && (
        <Form
          className="flex flex-one flex-column flex-space-between"
          onSubmit={onSubmit}>
          <div className="flex-center margin-2-bottom">
            <h1 className="login-header padding-0">
              We need some more information from you!
            </h1>
            <FormGroup
              data-test-id="phoneNumberFormGroup"
              className="login__phoneInput">
              <Label htmlFor="password">Mobile number</Label>

              <FormControl
                name="phoneNumber"
                type="phoneNumber"
                focusRef={(input: HTMLInputElement) => input?.focus?.()}
                value={phoneNumber}
                onChange={handleChange}
                data-test-id="phoneNumberInput"
                placeholder="+111223333333"
              />
              <Feedback
                className="font__xSmallRegular color-warning"
                open={!!error}>
                {error}
              </Feedback>
            </FormGroup>
            <div className="flex flex-one flex-column flex-space-between margin-4-top">
              <div className="margin-2-bottom">
                <Button
                  text="Send"
                  type="submit"
                  data-test-id="sendButton"
                  shouldGrow
                  variant={BUTTON_VARIANT.LOGIN}
                  isLoading={isLoading}
                  isDisabled={!phoneNumber || !!error}
                />
              </div>
              <div>
                <Button
                  type="button"
                  text="Cancel"
                  onClick={handleGoBack}
                  data-test-id="backButton"
                  variant={BUTTON_VARIANT.LOGIN_SECONDARY}
                  shouldGrow
                />
              </div>
            </div>
          </div>
        </Form>
      )}
      <LegacyDiv className="padding-3-top text-center">
        {!noPhoneNumber && (
          <Link
            data-test-id="forgotPasswordLink"
            className="link-primary margin-3-bottom"
            to={`${resetPasswordUrl}&domainGroupId=${domainGroupId}`}>
            Forgot password?
          </Link>
        )}
        <span>
          <span>Please read our</span>
          <Link
            data-test-id="privacyPolicyLink"
            to="https://www.quinyx.com/privacy#quinyx-application"
            className="link-primary padding-1-left"
            target="_blank"
            external>
            Privacy Policy
          </Link>
        </span>
      </LegacyDiv>
    </AuthPageWrapper>
  );
};

export default PasswordLogin;
