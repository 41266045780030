import { memo, ReactNode } from 'react';

import useTranslatedText from '../hooks/useTranslatedText';

type TranslateProps = {
  children: ReactNode | ((translations: string) => ReactNode);
  values?: string[];
  property?: string;
};

const Translate = memo(({ children, values, property }: TranslateProps) => {
  const translations = useTranslatedText(
    Array.isArray(values) ? values : children,
    property,
  );

  return typeof children === 'function' ? children(translations) : translations;
});

export default Translate;
