import hexToHsl from 'hex-to-hsl';

export const decimalToHexColor = (color) => {
  if (Number.isNaN(parseInt(color))) {
    return color;
  }
  const colorHexValue = color.toString(16).slice(-6);
  return colorHexValue.length < 6
    ? `#${colorHexValue.padStart(6, '0')}`
    : `#${colorHexValue}`;
};

export { hexToHsl };
