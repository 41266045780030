import React, { FC } from 'react';

import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import { Button, ButtonProps, LoadingButton } from '@shared/components/Button';

import Modal from '..';

interface ConfirmModalProps extends Partial<ReactModal.Props> {
  handlePrimaryAction: ButtonProps<'button'>['onClick'];
  handleSecondaryAction: ReactModal.Props['onRequestClose'];
  primaryActionText: ButtonProps<'button'>['text'];
  secondaryActionText: ButtonProps<'button'>['text'];
  useSecondaryButton?: boolean;
  testIdPrimaryAction?: ButtonProps<'button'>['data-test-id'];
  testIdSecondaryAction?: ButtonProps<'button'>['data-test-id'];
  open: ReactModal.Props['isOpen'];
  title: React.ReactNode;
  disabled?: ButtonProps<'button'>['disabled'];
  disabledPrimary?: ButtonProps<'button'>['disabled'];
  isLoading?: boolean;
  testId?: string;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  handlePrimaryAction,
  handleSecondaryAction,
  primaryActionText,
  secondaryActionText,
  useSecondaryButton,
  testIdPrimaryAction = 'testIdPrimaryAction',
  testIdSecondaryAction = 'testIdSecondaryAction',
  open,
  title,
  children,
  disabled,
  disabledPrimary,
  isLoading,
  testId,
  ...modalProps
}) => {
  return (
    <Modal
      testId={testId}
      isOpen={open}
      onRequestClose={handleSecondaryAction}
      className="confirmModal"
      {...modalProps}>
      <h4 className="text-capitalize-first-letter break-word">{title}</h4>
      <div className="padding-2-top padding-4-bottom">{children}</div>
      <div className="grid grid-cols-2 padding-2 gap-4">
        <LoadingButton
          text={primaryActionText}
          disabled={disabledPrimary || disabled || isLoading}
          isLoading={isLoading}
          data-test-id={testIdPrimaryAction}
          onClick={handlePrimaryAction}
        />
        <Button
          text={secondaryActionText}
          disabled={disabled || isLoading}
          data-test-id={testIdSecondaryAction}
          onClick={handleSecondaryAction}
          color="danger"
        />
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  handlePrimaryAction: PropTypes.func.isRequired,
  handleSecondaryAction: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]),
  open: PropTypes.bool.isRequired,
  testId: PropTypes.string,
};

export default ConfirmModal;
