import React from 'react';

const Info = ({ color = '#4773D4', ...props }) => (
  <svg width={28} height={28} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle stroke={color} cx={14.5} cy={14.5} r={10} />
      <path
        d="M14.5 20a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1zm0-12a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
        fill={color}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default Info;
