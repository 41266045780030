import React from 'react';

import classNames from 'classnames';
import { DayContentProps } from 'react-day-picker';

import LegacyDiv from '@shared/components/LegacyDiv';

import s from './ReactDayPicker.module.scss';

interface IDayWithWeekNumberProps extends DayContentProps {
  // TODO - renderCustomWeekNumberDayContents
  // eslint-disable-next-line react/no-unused-prop-types
  renderCustomWeekNumberDayContents?: boolean;
  isOutsideRange?: (day: Date) => boolean | null;
}

export const DayWithWeekNumber = ({
  date,
  isOutsideRange,
}: IDayWithWeekNumberProps) => {
  const dayNumber = date.getDate();

  // todo props.renderCustomWeekNumberDayContents
  const disabled = isOutsideRange && isOutsideRange(date);

  return (
    <LegacyDiv className={classNames(s.innerDayCell, disabled && s.disabled)}>
      {dayNumber}
    </LegacyDiv>
  );
};

export const withPropsDayWithWeekNumber = (
  Component: React.ComponentType<IDayWithWeekNumberProps>,
  renderCustomWeekNumberDayContents: boolean,
  isOutsideRange: (day: Date) => boolean | null,
) => {
  return (props: DayContentProps) => {
    return (
      <Component
        {...props}
        renderCustomWeekNumberDayContents={renderCustomWeekNumberDayContents}
        isOutsideRange={isOutsideRange}
      />
    );
  };
};
