import React from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';

import Qlogo from '../../../components/Icons/Qlogo';
import BlockerIcon from './icons/BlockerIcon';

import './index.scss';

const InternetExplorerBlocker = () => {
  return (
    <LegacyDiv className="flex flex-column height-100">
      <LegacyDiv className="internetExplorerBlocker__navbar">
        <LegacyDiv className="padding-4-left padding-4-right">
          <Qlogo fill="#ffffff" height="35px" width="35px" />
        </LegacyDiv>
      </LegacyDiv>
      <LegacyDiv className="internetExplorerBlocker__content">
        <BlockerIcon />
        <LegacyDiv className="bold margin-15-top">
          Ooops! We don’t support this web browser
        </LegacyDiv>
        <LegacyDiv className="internetExplorerBlocker__details">
          Please install a more recent browser to access your Quinyx account. We
          recommend you to use Chrome.
        </LegacyDiv>
      </LegacyDiv>
    </LegacyDiv>
  );
};

export default InternetExplorerBlocker;
