import { MutableRefObject } from 'react';

import { UseQueryResult } from '@tanstack/react-query';

import { CallParams, SuperAgentApi } from '@shared/containers/hooks/api/types';
import { ApiResponse } from '@shared/types';
import { isObject } from '@shared/utils';

import { DEFAULT_ERROR_MESSAGE } from './constants';
import { retrieveMessagesMap, transformApiDefaultState } from './index-old';

export * from './index-old';

const createId = () => `_${Math.random().toString(36).substring(2, 9)}`;

// wraps and API and transforms the response errors and adds status booleans
export const transformFetchApi =
  <P extends CallParams, T>(
    api: SuperAgentApi<T, P>,
    signalRef: MutableRefObject<string | null>,
  ) =>
  (params: P) => {
    const id = createId();
    signalRef.current = id;

    const calledApi = api(...params);
    const promiseForReturn = calledApi
      .then((res) => {
        if (signalRef.current === id) {
          return {
            ...transformApiDefaultState,
            data: res?.body,
            isAttempted: true,
            isSuccess: true,
          };
        }
      })
      .catch((error) => {
        if (signalRef.current === id) {
          const result = {
            ...transformApiDefaultState,
            errors: retrieveMessagesMap(error),
            originalError: error,
            isAttempted: true,
            isError: true,
          };

          throw result;
        }
      });

    return [promiseForReturn, calledApi.externalAbort] as const;
  };

export const transformApi =
  <P extends CallParams, T>(api: SuperAgentApi<T, P>) =>
  (params: P) => {
    const calledApi = api(...params);
    const promiseForReturn = calledApi
      .then((res) => ({
        ...transformApiDefaultState,
        data: res?.body,
        isAttempted: true,
        isSuccess: true,
      }))
      .catch((error) => {
        const result = {
          ...transformApiDefaultState,
          errors: retrieveMessagesMap(error),
          originalError: error,
          isAttempted: true,
          isError: true,
        };

        throw result;
      });

    return [promiseForReturn, calledApi.externalAbort] as const;
  };

export const errorMessageNot403 = <T = unknown>(
  error: UseQueryResult<ApiResponse<unknown>, T>['error'],
) => {
  if (isObject(error) && isObject(error.body) && error.body.status !== 403) {
    const { message } = error.body;
    return typeof message === 'string' ? message : DEFAULT_ERROR_MESSAGE;
  }
};
