import React from 'react';

import PropTypes from 'prop-types';

import { LegacyInput } from '@shared/components/legacy/LegacyInput';

import Password from './Password';

import './index.scss';

const FormControl = ({ autoComplete = 'off', focusRef, ...rest }) => {
  const { type, value } = rest;
  const props = { autoComplete, ...rest };
  switch (type) {
    case 'password':
      return <Password focusRef={focusRef} {...props} />;
    case 'textarea':
      return <textarea {...props} />;
    case 'percentage':
      return (
        <LegacyInput
          {...props}
          className={`input-percentage ${
            value < 0 || value > 100 ? 'hasError' : ''
          }`}
        />
      );
    default:
      return <LegacyInput ref={focusRef} {...props} />;
  }
};

FormControl.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  'data-test-id': PropTypes.string,
  onChange: PropTypes.func,
  autocomplete: PropTypes.string,
};

export default FormControl;
