import React, { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import classNames from 'classnames';
import t from 'prop-types';

import { LegacyInput } from '@shared/components/legacy/LegacyInput';
import LegacyDiv from '@shared/components/LegacyDiv';

import Translate from '../../Translate';
import { withForm } from '../Form';

import s from './index.module.scss';

/* If checkbox/radio is disabled formData for that checkbox/radio value will be undefined(applies when using Checkbox/Radio component inside Form component)  */
/* https://github.com/react-hook-form/react-hook-form/discussions/4413 */

// HTML spec does not support readOnly attributes on checkbox/radio
// There is a improvisation with the tabIndex attribute so checkbox/radio is not focusable

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  readOnly?: boolean;
  label: string;
  info?: string;
  className?: never;
}

export const Radio = forwardRef(
  (
    { label, info, readOnly, className: _className, ...props }: RadioProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const isDisabled = props.disabled || readOnly;

    return (
      <LegacyDiv
        className={classNames(s.radioWrap, isDisabled && s.radioDisabled)}>
        <label className={s.label}>
          <LegacyInput
            {...props}
            tabIndex={isDisabled ? -1 : props.tabIndex}
            ref={ref}
            className={s.radio}
            type="radio"
          />

          <span>
            <Translate>{label}</Translate>
          </span>
        </label>

        {info && (
          <span className={s.infoMsg}>
            <Translate>{info}</Translate>
          </span>
        )}
      </LegacyDiv>
    );
  },
);

Radio.displayName = 'Radio';
Radio.propTypes = {
  label: t.string.isRequired,
  info: t.string,
  disabled: t.bool,
  readOnly: t.bool,
  value: t.oneOfType([t.number, t.string]),
};

export const FormRadio = withForm(Radio);

FormRadio.propTypes = {
  name: t.string.isRequired,
};
