import * as sentry from '@sentry/browser';

import config from '../../config';

window.sentry = sentry;

/* eslint-disable-next-line */
const isEnabled = ERROR_REPORTING;

/* eslint-disable-next-line */
const environment = location.host;

const noopWhenDisabled = (func) => (isEnabled ? func : () => {});

const ignoreErrors = ['ResizeObserver loop limit exceeded'];

// ignore errors if browser does not support crypto.randomUUID (Chrome < v.92, Safari < v.15.4, Opera < v.78, FF < v.95)
if (!crypto.randomUUID) {
  ignoreErrors.push('crypto.randomUUID is not a function');
}

const init = () => {
  sentry.init({
    dsn: config.sentryDsn,
    environment,
    release: window.version || 'UNKNOWN',
    ignoreErrors,
    shutdownTimeout: 2000,
    beforeSend(event) {
      // recommended way by Sentry to filter out errors in Chrome extensions
      if (event.message && event.message.startsWith('ReportingObserver')) {
        if (
          event.extra &&
          event.extra.body &&
          event.extra.body.sourceFile &&
          event.extra.body.sourceFile.startsWith('chrome-extension')
        ) {
          return null;
        }
      }

      return event;
    },
  });
};

const setUser = (user) => {
  sentry.configureScope((scope) => {
    scope.setUser({ id: user.id });
  });
};

const reportError = (error) => {
  if (!(error instanceof Error)) return;

  sentry.captureException(error);
};

export default {
  init: noopWhenDisabled(init),
  setUser: noopWhenDisabled(setUser),
  reportError: noopWhenDisabled(reportError),
};
