import { HOSTNAME } from '@shared/constants';
import { Environment, Hostname } from '@shared/types';

type EnvironmentParameter = {
  showOnEnvironment: Environment;
  showOnHostnames?: never;
};
type HostnameParameter = {
  showOnEnvironment?: never;
  showOnHostnames: Hostname | Hostname[];
};
type FeatureToggleParams = EnvironmentParameter | HostnameParameter;

// NODE_ENV will be 'production' on all builds, so if we want to show something on localhost only (npm run dev), use showOnEnvironment: 'development'
/**
 * @description Create a feature toggle for things you are working on.
 * It's smart naming it with a feature specific name to easily remove all usages later on.
 * @example
 * export const myNewFeatureToggle = createFeatureToggle = ({ showOnEnvironment: [HOSTNAME.LOCAL_TEST] });
 * const Component = () => return (<> { myNewFeatureToggle && <div>Amazing content</div> } </>)
 * @returns {boolean} Returns true if passed environment/host names are correct at runtime.
 */
export const createFeatureToggle = ({
  showOnEnvironment,
  showOnHostnames,
}: FeatureToggleParams) => {
  if (showOnEnvironment && showOnHostnames)
    throw new Error(
      'Pick either environment or hostnames to calculate hidden, do not use both',
    );
  if (showOnEnvironment) {
    return showOnEnvironment === process.env.NODE_ENV;
  }

  const showOnHostnamesArray = Array.isArray(showOnHostnames)
    ? showOnHostnames
    : [showOnHostnames];

  return showOnHostnamesArray.some(
    (hostname) =>
      hostname === window.location.hostname ||
      (hostname === HOSTNAME.TEST &&
        (window.location.hostname === 'localhost' ||
          window.location.hostname === HOSTNAME.LOCAL_TEST)) ||
      window.location.hostname.includes(HOSTNAME.VERCEL),
  );
};
