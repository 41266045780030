import React, { LabelHTMLAttributes } from 'react';

import classNames from 'classnames';

import useTranslate from '@shared/components/hooks/useTranslate';

import style from './index.module.scss';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  label: string;
  required?: boolean | string;
  className?: never;
}

const Label = ({ label = '', required, ...props }: LabelProps) => {
  const translate = useTranslate();
  // Display asterisk only for true or string 'true' values
  const showAsterisk = required === true || required === 'true';

  return (
    <label
      className={classNames(style.label, showAsterisk && style.labelRequired)}
      {...props}>
      {translate(label)}
    </label>
  );
};

export default Label;
