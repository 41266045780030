import React, { useState } from 'react';

import { LegacyInput } from '@shared/components/legacy/LegacyInput';
import LegacyDiv from '@shared/components/LegacyDiv';

import EyeIcon from '../../../Icons/Eye';

import './index.scss';

const Password = ({ focusRef, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <LegacyDiv className="password-box">
      <LegacyInput
        className="password-box__input"
        ref={focusRef}
        {...props}
        type={showPassword ? 'text' : 'password'}
      />
      <EyeIcon
        onClick={() => setShowPassword((show) => !show)}
        height={38}
        width={38}
        className={
          !showPassword
            ? 'password-box__unmask-btn icon--eye__disabled'
            : 'password-box__unmask-btn'
        }
      />
    </LegacyDiv>
  );
};

export default Password;
