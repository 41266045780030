import React from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';

import Button from '../../ButtonLogin';
import { BUTTON_VARIANT } from '../../ButtonLogin/constants';

import './index.scss';

const AuthPageRight = () => {
  /*
  The copy on this page has two states, the copy before a predefined time and date,
  and the copy after that time and date.
  The copyDate should always be defined according to this format:
  2022-12-31T23:59:59.000+0200
  */
  const copyDate = '2024-03-30T23:59:59.000+0200';
  const copyBefore = {
    title: 'Product Innovation Keynote\nSpring 2024',
    text: 'Learn about our brand new communication solution\nto enable two-way communication and to build communities.\nSign up today.',
    buttonLabel: 'Sign up',
    buttonLink: 'https://hubs.ly/Q02b097F0',
  };
  const copyAfter = {
    title: 'Check out our\nProduct Hub',
    text: 'Stay up-to-date on the latest and greatest in Quinyx',
    buttonLabel: 'Visit now',
    buttonLink:
      'https://www.quinyx.com/product-hub?utm_campaign=new_login_page&utm_source=login_page&utm_medium=product_hub',
  };
  const copy =
    Date.now() < new Date(copyDate).getTime() ? copyBefore : copyAfter;
  return (
    <LegacyDiv className="padding-4">
      <h1 className="authPageRightContent__title">{copy.title}</h1>
      <LegacyDiv className="authPageRightContent__text">{copy.text}</LegacyDiv>
      <LegacyDiv className="flex-row flex-justify-center">
        <Button
          text={copy.buttonLabel}
          variant={BUTTON_VARIANT.LOGIN_SECONDARY}
          onClick={() => window.open(copy.buttonLink, '_blank')}
        />
      </LegacyDiv>
    </LegacyDiv>
  );
};

export default AuthPageRight;
