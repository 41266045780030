import React from 'react';

import { Router } from '@reach/router';

import SsoError from './Error';
import SsoLogin from './Login';

// sub path 'login/:seo' is intercepted by the server and redirect to Core SSO login

const SingleSignOn = () => (
  <Router>
    <SsoError path="error" />
    <SsoLogin default />
  </Router>
);

export default SingleSignOn;
