import React, { FC, MouseEvent } from 'react';

import PropTypes from 'prop-types';

import LegacyDiv from '@shared/components/LegacyDiv';

import Cross from '../Icons/Cross';
import Button from '../legacy/Button';

import './index.scss';

interface CrossIconButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  'data-test-id'?: string;
  disabled?: boolean;
}

const CrossIconButton: FC<CrossIconButtonProps> = ({
  onClick,
  className = 'clear-button',
  'data-test-id': dataTestId = 'closeButton',
  disabled,
}) => {
  return (
    <Button
      type="button"
      disabled={disabled}
      data-test-id={dataTestId}
      className={`flex flex-center ${className}`}
      onClick={onClick}>
      <LegacyDiv>
        <Cross />
      </LegacyDiv>
    </Button>
  );
};

CrossIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CrossIconButton;
