import React from 'react';

const ArrowRight = (props) => (
  <svg
    width={props.width || '16px'}
    height={props.height || '16px'}
    viewBox="0 0 16 16"
    {...props}>
    <path
      d="M6.005 13.733a1 1 0 1 1-1.414-1.414l4.245-4.246a.996.996 0 0 0 0-1.419l-4.24-4.24A1 1 0 1 1 6.01.999l4.952 4.952a2 2 0 0 1-.001 2.826l-4.956 4.956z"
      fill="#000"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowRight;
