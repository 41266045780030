import React, { forwardRef, useRef, useState } from 'react';

import t from 'prop-types';
import ReactNumericInput, {
  NumericInputProps as ReactNumericInputProps,
} from 'react-numeric-input';

import useTranslate from '@shared/components/hooks/useTranslate';
import LegacyDiv from '@shared/components/LegacyDiv';
import { isNil } from '@shared/utils';

import { withFormControl } from '../Form';
import Label from '../Label';

import s from './index.module.scss';

interface NumericInputProps extends ReactNumericInputProps {
  label?: string;
  info?: string;
  error?: string;
  className?: never;
}

// Zero issue is a known bug in react-numeric-input
// When you try to type a number like 1.0 (intending to write 1.01 later), it will automatically convert it to 1
const zeroRegex = /^-?\d+\.\d*0$/;
const useZeroFix = (
  props: Pick<NumericInputProps, 'format' | 'precision' | 'onInput' | 'value'>,
) => {
  const refVal = useRef(String(props.value ?? ''));
  // We don't apply this fix if:
  // - format is provided because the fix is relying on 'format' prop
  // - precision is provided because the lib is working correctly in this case
  const applyZeroFix = isNil(props.format) && isNil(props.precision);
  if (!applyZeroFix) return { onInput: props.onInput, format: props.format };

  const onInput = (e: React.FormEvent<HTMLInputElement>) => {
    // just dealing with quirky typings
    const target = e.target as HTMLInputElement;
    const { value } = target;

    if (value.at(-1) === '.' && refVal.current.includes('.')) {
      // if user erased the last decimal, we remove the dot (original react-numeric-input behavior)
      const updValue = value.slice(0, -1);
      target.value = updValue;
      refVal.current = updValue;
    } else {
      refVal.current = value;
    }

    props.onInput?.(e);
  };

  // when react-numeric-input get "x.0" it provides "x" to format function,
  // but we use the real value we tracked via onInput
  const format = (value: number | null) => {
    // we leave the lib work as usual for all other scenarios (there are also non-input cases)
    if (refVal.current.match(zeroRegex)) return refVal.current;
    return value === null ? '' : String(value);
  };

  return { onInput, format };
};

export const NumericInput = forwardRef<HTMLInputElement, NumericInputProps>(
  (
    {
      label = '',
      info,
      error,
      placeholder,
      required,
      id: propId,
      className: _className,
      onChange,
      name: _name, // We cant use name with ReactNumericInput component
      ...props
    },
    ref,
  ) => {
    const [uuid] = useState(() => crypto.randomUUID());
    const id = propId ?? uuid;
    const translate = useTranslate();

    const { onInput, format } = useZeroFix(props);

    return (
      <LegacyDiv className={s.numericInputWrap}>
        {label && <Label htmlFor={id} label={label} required={required} />}

        <ReactNumericInput
          id={id}
          {...props}
          ref={(el: ReactNumericInput & { refsInput: HTMLInputElement }) => {
            if (el && typeof ref === 'function') {
              ref(el.refsInput);
            }
          }}
          className={s.numericInput}
          placeholder={placeholder ? translate(placeholder) : ''}
          onChange={onChange}
          onInput={onInput}
          format={format}
        />

        {error && <span className={s.errorMsg}>{translate(error)}</span>}

        {info && <span className={s.infoMsg}>{translate(info)}</span>}
      </LegacyDiv>
    );
  },
);

NumericInput.displayName = 'NumericInput';
NumericInput.propTypes = {
  label: t.string,
  info: t.string,
  error: t.string,
  placeholder: t.string,
  precision: t.number,
  step: t.number,
  required: t.bool,
};

export const FormNumericInput = withFormControl(NumericInput);

FormNumericInput.propTypes = {
  name: t.string.isRequired,
};
