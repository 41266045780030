export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss'; // 1986-09-04T08:30:25
export const ISO_FORMAT_WITHOUT_SECONDS = 'YYYY-MM-DDTHH:mm'; // 1986-09-04T08:30
export const TIMESTAMP_MS_FORMAT = 'x';

export const DATE_FORMAT = 'YYYY-MM-DD'; // 1986-09-04
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'; // 1986-09-04 08:30
export const DATE_TIME_SECONDS_FORMAT = 'YYYY-MM-DD HH:mm:ss'; // 1986-09-04 08:30:00
export const TIME_FORMAT = 'HH:mm'; // 08:30
export const TIME_SECONDS_FORMAT = 'HH:mm:ss'; // 08:30:25
export const YEAR_FORMAT = 'YYYY'; // 1986
export const DAY_OF_MONTH_FORMAT = 'D'; // 4 or 14
export const DAY_OF_MONTH_PAD_FORMAT = 'DD'; // 04 or 14
export const MONTH_OF_YEAR_FORMAT = 'M'; // 2 or 12
export const MONTH_OF_YEAR_PAD_FORMAT = 'MM'; // 02 or 12
export const MONTH_DAY_FORMAT = 'MM-DD'; // 12-01

export const LOCALE_DATE_FORMAT = 'L'; // '09/04/1986', depending on locale
export const LOCALE_DATE_WEEKDAY_FORMAT = 'L dddd'; // '09/04/1986 Wednesday', depending on locale
export const LOCALE_DATE_WEEKDAY_SHORT_FORMAT = 'L ddd'; // '09/04/1986 Wed', depending on locale
export const LOCALE_DATE_MONTH_NAME_FORMAT = 'LL'; // 'September 4, 1986', depending on locale
export const LOCALE_DATE_MONTH_NAME_SHORT_FORMAT = 'll'; // 'Sep 4, 1986', depending on locale
export const LOCALE_DATE_NAME_FORMAT = 'ddd ll'; // Sun Jan 21, 2019, depending on locale
export const LOCALE_DATE_FULL_NAME_FORMAT = 'dddd ll'; // Sunday Jan 21, 2019, depending on locale
export const LOCALE_DATE_TIME_FORMAT = 'L LT'; // '09/04/1986 8:30 PM', depending on locale
export const LOCALE_DATE_TIME_SECONDS_FORMAT = 'L LTS'; // '09/04/1986 8:30:25 PM', depending on locale
export const LOCALE_DATE_TIME_NAME_FORMAT = 'ddd ll LT'; // 'Sun Jan 21, 2019 8:30 PM', depending on locale
export const LOCALE_DATE_MONTH_NAME_TIME_FORMAT = 'll LT'; // 'Sep 4, 1986 8:30 PM', depending on locale
export const LOCALE_TIME_FORMAT = 'LT'; // '8:30 PM', depending on locale
export const LOCALE_TIME_SECONDS_FORMAT = 'LTS'; // '8:30:25 PM', depending on locale
export const LOCALE_DAY_NAME_FORMAT = 'dddd'; // 'Sunday', depending on locale
export const LOCALE_DAY_NAME_SHORT_FORMAT = 'ddd'; // 'Sun', depending on locale
export const LOCALE_DAY_NAME_COMPACT_FORMAT = 'dd'; // 'Su', depending on locale
export const LOCALE_DATE_WEEKDAY_DAY_FORMAT = 'ddd D'; // 'Sun 21'

export const EPOCH_TIME = '1970-01-01T00:00:00';
