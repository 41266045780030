import React, { useState } from 'react';

import classNames from 'classnames';
import ReactSelect, { components, createFilter } from 'react-select';

import Translate from '../../Translate';

import './index.scss';

import ArrowDown from '../../../../ManagerPortal/components/Icons/NewArrowDown';

const multiValueLabelStyles = {
  multiValue: (base) => ({
    ...base,
    maxWidth: '100%',
  }),
  multiValueLabel: (base) => ({
    ...base,
    display: 'block',
    maxWidth: 'calc(100% - 22px)',
  }),
};

export const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDown />
      </components.DropdownIndicator>
    )
  );
};

const Select = React.forwardRef(
  (
    {
      className,
      styles,
      id = 'SelectBox',
      dataTestId,
      placeholder = <Translate>Select</Translate>,
      onChange,
      isMulti = false,
      menuPosition,
      ignoreAccents = false,
      dropdownIndicator,
      components: selectComponents,
      ...rest
    },
    forwardedRef,
  ) => {
    const [menuStyles, setMenuStyles] = useState({ top: 0 });
    const handleUpdateMenuOnScroll = () => {
      setMenuStyles({ top: 0 });
      return false;
    };
    const getSelectComponents = () => {
      return dropdownIndicator
        ? {
            ...selectComponents,
            DropdownIndicator,
          }
        : selectComponents;
    };

    const shouldUpdateOnScroll = menuPosition === 'fixed';

    const shouldUpdateOnScrollStyles = shouldUpdateOnScroll
      ? {
          menuPortal: (base) => ({
            ...menuStyles,
            ...base,
          }),
        }
      : undefined;
    return (
      <ReactSelect
        ref={forwardedRef}
        filterOption={createFilter({ ignoreAccents })}
        menuPosition={menuPosition}
        styles={{
          ...shouldUpdateOnScrollStyles,
          ...multiValueLabelStyles,
          ...styles,
        }}
        className={classNames(className, 'default-select', `select-${id}`, {
          'new-select': dropdownIndicator,
        })}
        classNamePrefix="react-select"
        inputId={id}
        placeholder={placeholder}
        noOptionsMessage={() => <Translate>No options</Translate>}
        onChange={(selection, action) => {
          onChange(isMulti && selection === null ? [] : selection, action);
        }}
        isMulti={isMulti}
        components={getSelectComponents()}
        closeMenuOnScroll={
          shouldUpdateOnScroll ? handleUpdateMenuOnScroll : undefined
        }
        {...rest}
      />
    );
  },
);

export { components } from 'react-select';
export default Select;
