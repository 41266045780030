export const b64UTF8Encoder = (str) => {
  // From https://stackoverflow.com/q/30106476/3780766
  const encodedString = encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    (_, p1) => {
      return String.fromCharCode(`0x${p1}`);
    },
  );
  return btoa(encodedString);
};
