import React from 'react';

import classNames from 'classnames';

interface LegacyInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const LegacyInput = React.forwardRef<HTMLInputElement, LegacyInputProps>(
  ({ className, ...rest }, ref) => {
    return (
      <input
        ref={ref}
        className={classNames('legacyInput', className || '')}
        {...rest}
      />
    );
  },
);
