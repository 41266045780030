const config = {
  lang: 'en',
  languages: [
    {
      id: 'en',
      name: 'English (GB)',
      locale: 'en-gb',
      translationsId: 'en_GB',
      default: true,
      bcp47Code: 'en-GB',
    },
    {
      id: 'en-us',
      name: 'English (US)',
      locale: 'en',
      translationsId: 'en',
      bcp47Code: 'en-US',
    },
    {
      id: 'en-ca',
      name: 'English (CA)',
      locale: 'en-ca',
      translationsId: 'en',
      bcp47Code: 'en-CA',
    },
    {
      id: 'sv',
      name: 'Svenska',
      locale: 'sv',
      translationsId: 'sv',
      bcp47Code: 'sv-SE',
    },
    {
      id: 'no',
      name: 'Norsk',
      locale: 'nb',
      translationsId: 'no',
      bcp47Code: 'no-NO',
    },
    {
      id: 'fi',
      name: 'Suomi',
      locale: 'fi',
      translationsId: 'fi',
      bcp47Code: 'fi-FI',
    },
    {
      id: 'dk',
      name: 'Dansk',
      locale: 'da',
      translationsId: 'dk',
      bcp47Code: 'da-DK',
    },
    {
      id: 'de',
      name: 'Deutsch',
      locale: 'de',
      translationsId: 'de',
      bcp47Code: 'de-DE',
    },
    {
      id: 'fr',
      name: 'Français',
      locale: 'fr',
      translationsId: 'fr',
      bcp47Code: 'fr-FR',
    },
    {
      id: 'fr-ca',
      name: 'Français (CA)',
      locale: 'fr-ca',
      translationsId: 'fr',
      bcp47Code: 'fr-CA',
    },
    {
      id: 'pt',
      name: 'Português',
      locale: 'pt',
      translationsId: 'pt',
      bcp47Code: 'pt-PT',
    },
    {
      id: 'es',
      name: 'Español',
      locale: 'es',
      translationsId: 'es',
      bcp47Code: 'es-ES',
    },
    {
      id: 'it',
      name: 'Italiano',
      locale: 'it',
      translationsId: 'it',
      bcp47Code: 'it-IT',
    },
    {
      id: 'nl',
      name: 'Nederlands',
      locale: 'nl',
      translationsId: 'nl',
      bcp47Code: 'nl-NL',
    },
    {
      id: 'zh-cn',
      name: '汉语',
      locale: 'zh-cn',
      translationsId: 'zh_CN',
      bcp47Code: 'zh-CN',
    },
    {
      id: 'ja',
      name: '日本語',
      locale: 'ja',
      translationsId: 'ja',
      bcp47Code: 'ja-JP',
    },
    {
      id: 'ko',
      name: '한국어',
      locale: 'ko',
      translationsId: 'ko',
      bcp47Code: 'ko-KR',
    },
    {
      id: 'ru-ru',
      name: 'Русский',
      locale: 'ru',
      translationsId: 'ru_RU',
      bcp47Code: 'ru-RU',
    },
    {
      id: 'pl',
      name: 'Polski',
      locale: 'pl',
      translationsId: 'pl',
      bcp47Code: 'pl-PL',
    },
    {
      id: 'lt',
      name: 'Lietuvių',
      locale: 'lt',
      translationsId: 'lt',
      bcp47Code: 'lt-LT',
    },
    {
      id: 'lv',
      name: 'Latviski',
      locale: 'lv',
      translationsId: 'lv',
      bcp47Code: 'lv-LV',
    },
    {
      id: 'et',
      name: 'Eesti',
      locale: 'et',
      translationsId: 'et',
      bcp47Code: 'et-EE',
    },
  ],
  staffPortalLanguages: [
    {
      id: 'en-gb',
      name: 'English (GB)',
      locale: 'en-gb',
      translationsId: 'en',
      default: true,
    },
    {
      id: 'en-us',
      name: 'English (US)',
      locale: 'en',
      translationsId: 'en',
    },
    { id: 'sv', name: 'Svenska', locale: 'sv', translationsId: 'sv' },
    {
      id: 'da',
      name: 'Dansk',
      locale: 'da',
      translationsId: 'da',
    },
    {
      id: 'cs',
      name: 'Czech',
      locale: 'cs',
      translationsId: 'cs',
    },
    { id: 'de', name: 'Deutsch', locale: 'de', translationsId: 'de' },
    {
      id: 'nl',
      name: 'Nederlands',
      locale: 'nl',
      translationsId: 'nl',
    },
    { id: 'fi', name: 'Suomi', locale: 'fi', translationsId: 'fi' },
    {
      id: 'fr',
      name: 'Français',
      locale: 'fr',
      translationsId: 'fr',
    },
    {
      id: 'fr-ca',
      name: 'Français (CA)',
      locale: 'fr-ca',
      translationsId: 'fr_CA',
    },
    {
      id: 'el',
      name: 'Ελληνικά',
      locale: 'el',
      translationsId: 'el',
    },
    {
      id: 'it',
      name: 'Italiano',
      locale: 'it',
      translationsId: 'it',
    },
    {
      id: 'ja',
      name: '日本語',
      locale: 'ja',
      translationsId: 'ja',
    },
    {
      id: 'ko',
      name: '한국어',
      locale: 'ko',
      translationsId: 'ko',
    },
    { id: 'no', name: 'Norsk', locale: 'nb', translationsId: 'no' },
    {
      id: 'pl',
      name: 'Polski',
      locale: 'pl',
      translationsId: 'pl',
    },
    {
      id: 'pt',
      name: 'Português',
      locale: 'pt',
      translationsId: 'pt',
    },
    { id: 'es', name: 'Español', locale: 'es', translationsId: 'es' },
    { id: 'th', name: 'ไทย', locale: 'th', translationsId: 'th' },
    {
      id: 'zh-cn',
      name: '汉语',
      locale: 'zh-cn',
      translationsId: 'zh_CN',
    },
    {
      id: 'zh-hk',
      name: '漢語',
      locale: 'zh-hk',
      translationsId: 'zh_HK',
    },
    {
      id: 'tr',
      name: 'Türkçe',
      locale: 'tr',
      translationsId: 'tr',
    },
    {
      id: 'lt',
      name: 'Lietuvių',
      locale: 'lt',
      translationsId: 'lt',
    },
    {
      id: 'lv',
      name: 'Latviski',
      locale: 'lv',
      translationsId: 'lv',
    },
    {
      id: 'et',
      name: 'Eesti',
      locale: 'et',
      translationsId: 'et',
    },
  ],
  sentryDsn: 'https://d7479fb099a94633b136ebcd3010f223@sentry.io/1288916',
  snowplowCollector: 'tracker.quinyx.com',
};

module.exports = config;
