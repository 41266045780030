import React from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';

import Copy from '@ManagerPortal/components/Icons/Copy';

import useTranslate from '../hooks/useTranslate';
import Button from '../legacy/Button';
import { notify } from '../Notifications/utils';
import ToolTip from '../Tooltip';

import './index.scss';

type CodeSnippetProps = {
  code: string;
  valueOnCopySuccess?: string;
};

const CodeSnippet = ({ code, valueOnCopySuccess }: CodeSnippetProps) => {
  const translate = useTranslate();

  const onClickCopy = async () => {
    try {
      await navigator.clipboard.writeText(code);
      notify(
        valueOnCopySuccess
          ? translate('label.copiedToClipboard.success_withValue', {
              value: valueOnCopySuccess,
            })
          : translate('label.copiedToClipboard.success'),
        'success',
      );
    } catch (error) {
      notify(translate('label.copiedToClipboard.error'), 'error');
    }
  };
  return (
    <LegacyDiv className="codeSnippet">
      <pre className="codeSnippet__codeContainer">
        <code className="codeSnippet__code">{code}</code>
      </pre>
      <ToolTip
        className="height-100"
        content={translate('Copy key')}
        hoverDelay={2000}>
        <Button
          className="codeSnippet__copyButton"
          onClick={onClickCopy}
          data-test-id="codeSnippet__copyButton">
          <Copy />
        </Button>
      </ToolTip>
    </LegacyDiv>
  );
};

export default CodeSnippet;
