import React, { ReactElement, ReactNode } from 'react';

import TooltipLite from '@shared/components/Tooltip/TooltipLite';

import './index.scss';

export type TooltipDirection =
  | 'up'
  | 'up-start'
  | 'up-end'
  | 'down'
  | 'down-start'
  | 'down-end'
  | 'left'
  | 'left-start'
  | 'left-end'
  | 'right'
  | 'right-start'
  | 'right-end';

type TooltipProps = {
  children: ReactElement;
  arrow?: boolean;
  arrowSize?: number;
  background?: string;
  className?: string;
  color?: string;
  content: ReactNode;
  direction?: TooltipDirection;
  distance?: number;
  eventOff?: string;
  eventOn?: string;
  eventToggle?: string;
  forceDirection?: boolean;
  hoverDelay?: number;
  isOpen?: boolean;
  mouseOutDelay?: number;
  padding?: string | number;
  styles?: object;
  tagName?: string;
  tipContentHover?: boolean;
  tipContentClassName?: string;
  useHover?: boolean;
  useDefaultStyles?: boolean;
  zIndex?: number;
  onToggle?: (showTip: boolean) => void;
  arrowContent?: ReactNode;
};

export const ToolTip = ({
  arrow = false,
  arrowSize = 5,
  direction = 'up',
  ...rest
}: TooltipProps) => {
  return (
    <TooltipLite
      className="flex position-absolute"
      arrow={arrow}
      arrowSize={arrowSize}
      direction={direction}
      padding="5px"
      {...rest}
    />
  );
};

export default ToolTip;
