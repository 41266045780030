import React, { forwardRef, ReactElement, useRef } from 'react';

import t from 'prop-types';
// @ts-expect-error Version of `react-select` we currently use doesn't have ts declarations
import Creatable from 'react-select/creatable';

import useTranslate from '@shared/components/hooks/useTranslate';
import LegacyDiv from '@shared/components/LegacyDiv';

import { withFormControl } from '../../Form';
import Label from '../../Label';
import { Option } from '../types';
import { ActionMeta, ComboBoxProps, Options } from './types';
import {
  findByValue,
  isArray,
  multiValueAsValue,
  singleValueAsValue,
} from './utils';

import s from '../Select/index.module.scss';

const ComboBoxComponent = <Value = unknown, IsMulti extends boolean = false>(
  {
    label,
    required,
    value,
    error,
    info,
    placeholder = 'Select',
    options,
    onChange,
    id = crypto.randomUUID(),
    className,
    className: _className,
    isMulti: _isMulti,
    disabled,
    readOnly,
    children,
    ...props
  }: ComboBoxProps<Value, IsMulti>,
  ref: unknown,
) => {
  const isMulti = _isMulti || false;
  const translate = useTranslate();
  const translatedOptions = useRef(
    options.map((option) => ({
      ...option,
      label: translate(option.label),
      value: option.value,
    })),
  );

  const handleChange = (
    change: Option<Value> | Options<Value>,
    actionMeta: ActionMeta<Option<Value> | Options<Value>>,
  ) => {
    if (actionMeta.removedValue && actionMeta.removedValue.isFixed) return;

    if (isArray(change)) {
      onChange(multiValueAsValue(change.map((option) => option.value)));
    } else {
      onChange(singleValueAsValue(change?.value ?? null));
    }
  };

  const selectedOptions = findByValue(
    value,
    translatedOptions.current,
    isMulti,
  );

  const handleCreateOption = (createdValue: Value) => {
    translatedOptions.current.push({
      label: String(createdValue),
      value: createdValue,
    });
    if (isArray(value)) {
      onChange(multiValueAsValue([...value, createdValue]));
    } else onChange(singleValueAsValue(createdValue));
  };

  return (
    <LegacyDiv className={s.selectWrap}>
      {label && <Label htmlFor={id} label={label} required={required} />}

      <Creatable
        {...props}
        ref={ref}
        inputId={id}
        isMulti={isMulti}
        isDisabled={disabled || readOnly}
        classNamePrefix="custom-select"
        placeholder={translate(placeholder)}
        value={selectedOptions}
        onChange={handleChange}
        options={translatedOptions.current}
        onCreateOption={handleCreateOption}
        isClearable={!options.some((option) => option.isFixed)}
      />

      {error && <span className={s.errorMsg}>{translate(error)}</span>}

      {info && <span className={s.infoMsg}>{translate(info)}</span>}
    </LegacyDiv>
  );
};

export const ComboBox = forwardRef(ComboBoxComponent) as <
  Value = unknown,
  IsMulti extends boolean = false,
>(
  // eslint "no-use-before-define" below needed to hack passing generics to render func of forwardRef
  // eslint-disable-next-line no-use-before-define
  props: ComboBoxProps<Value, IsMulti> & { ref?: unknown },
) => ReactElement;
export const FormComboBox = withFormControl(ComboBox);

// @ts-expect-error we need this exception for `displayName` because we assert type instead of `forwardedRef` to enable generics passing
ComboBox.displayName = 'ComboBox';
// @ts-expect-error we need this exception for `displayName` because we assert type instead of `forwardedRef` to enable generics passing
ComboBox.propTypes = {
  label: t.string,
  required: t.oneOfType([t.bool, t.string]),
  error: t.string,
  info: t.string,
  placeholder: t.string,
  options: t.arrayOf(
    t.shape({
      label: t.string,
      value: t.oneOfType([t.string, t.number]),
      isDisabled: t.bool,
    }),
  ),
  onChange: t.func,
  isMulti: t.bool,
  isClearable: t.bool,
  disabled: t.bool,
  readOnly: t.bool,
};

FormComboBox.propTypes = {
  name: t.string.isRequired,
  onChange: t.func,
};
