const _validate = (validator, value) => {
  return !validator._test(value) ? validator._message : '';
};

export const createValidator = (test, message) => {
  return {
    _test: test,
    _message: message,
  };
};

export const validate = (validator, value) => {
  return _validate(validator, value);
};

export const validateAll = (validators, value) => {
  return validators.reduce((acc, validator) => {
    if (acc) {
      return acc;
    }
    return _validate(validator, value);
  }, false);
};
