import { useEffect, useRef } from 'react';

const useDelay = (callback, delay) => {
  const timeoutId = useRef();

  const handleClearTimeout = () => {
    if (!timeoutId.current) return;

    clearTimeout(timeoutId.current);
    timeoutId.current = null;
  };

  const handleSetTimeout = () => {
    timeoutId.current = setTimeout(callback, delay);
  };

  const handleReset = () => {
    handleClearTimeout();
    handleSetTimeout();
  };

  useEffect(() => {
    handleClearTimeout();
    if (!delay) return;

    handleSetTimeout();

    return handleClearTimeout;
  }, [delay]);

  return handleReset;
};

export default useDelay;
