import { PostToParentEvent } from '@shared/types';

export const postMessageToParent = (message: PostToParentEvent) => {
  // Not embedded in iframe, do not post message
  if (window.self === window.top) {
    return;
  }

  window.parent.postMessage(message, '*');
};
