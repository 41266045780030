import { isObject } from '@shared/utils';

import {
  MainMessageError,
  OriginalError,
  TransformedApiError,
  TransformedFieldMessageError,
  TransformedMainMessageError,
} from '../types';

export const isTransformedApiError = (
  error: unknown,
): error is TransformedApiError =>
  isObject(error) && 'errors' in error && isObject(error.errors);

export function assertIsMainMessageError(
  error: unknown,
): asserts error is TransformedMainMessageError {
  if (!(isTransformedApiError(error) && 'mainMessage' in error.errors)) {
    throw error;
  }
}

export function assertIsFieldErrorDictionary(
  error: unknown,
): asserts error is TransformedFieldMessageError {
  if (!isTransformedApiError(error)) {
    throw error;
  }
}

export const isMainMessageError = (error: unknown): error is MainMessageError =>
  isObject(error) && 'mainMessage' in error;

export const isTransformedMainMessageError = (
  error: unknown,
): error is TransformedMainMessageError => {
  if (isTransformedApiError(error)) {
    return 'mainMessage' in error.errors;
  }
  return false;
};

export const isOriginalError = (error: unknown): error is OriginalError =>
  isObject(error) &&
  'response' in error &&
  'status' in error &&
  'body' in error;
