import getParams from '@shared/utils/getParams';

export const handleRedirectOfTargetUri = (targetUri: string): string => {
  if (targetUri.includes('https://')) {
    console.log(`Full redirect to other Quinyx target: ${targetUri}`);
    window.location.href = targetUri;
  } else {
    console.log(`Relative redirect to internal URI: ${targetUri}`);
    return targetUri;
  }
  return '/';
};

type ContextAndTarget = {
  context: string;
  targetPath: string;
  switchingAccount: boolean;
};

const getTargetPath = (): string => {
  const search = getParams();
  const targetPath = search.get('targetPath');
  if (targetPath === null) {
    const path = window.location.pathname + window.location.search;
    return path.includes('javascript') ? '/' : path;
  }
  // Very minimal URL encoding detection
  if (window.location.search.includes('%')) {
    return encodeURIComponent(targetPath);
  }
  return targetPath;
};

const getContext = (): string => {
  const search = getParams();
  const context = search.get('context');
  if (context === null || context.includes('javascript') || context === '/') {
    return 'quinyxweb';
  }
  return context;
};

const getSwitchAccount = (): boolean => {
  const search = getParams();
  const context = search.get('switchAccount');
  if (context === null || context.includes('javascript')) {
    return false;
  }
  return true;
};
export const getContextAndTargetPath = (): ContextAndTarget => {
  const context = getContext();
  const targetPath = getTargetPath();
  const isSwitchingAccount = getSwitchAccount();
  const ct: ContextAndTarget = {
    context,
    targetPath,
    switchingAccount: isSwitchingAccount,
  };
  return ct;
};
