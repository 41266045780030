export const isEmbeddedInApp = () => !!window.embeddingApplication;

export const isIOSWebView = !!window?.webkit?.messageHandlers?.iOS;
export const isAndroidWebView = !!window?.Android;

export const iOSPostMessage = (message) =>
  window.webkit.messageHandlers.iOS?.postMessage(message);
export const androidPostMessage = (message) =>
  window.Android?.postMessage(message);

export const postMessageToWebView = (message) => {
  if (isIOSWebView) iOSPostMessage(message);
  if (isAndroidWebView) androidPostMessage(message);
};
