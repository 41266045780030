import React from 'react';

import AuthPageWrapper from '@shared/components/AuthPageWrapper';
import Button from '@shared/components/ButtonLogin';
import { BUTTON_VARIANT } from '@shared/components/ButtonLogin/constants';
import Link from '@shared/components/Link';
import { useNavigate } from '@shared/utils/route';

const CheckEmailMessage = ({ message = 'Please check your email', url }) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(url || '/');
    navigate(0);
  };
  return (
    <AuthPageWrapper>
      <div className="flex-center margin-8-bottom">
        <h1 className="login-header padding-0">Almost there!</h1>
      </div>
      <div className="flex-one overflow-hidden">{message}</div>
      <div className="flex-one overflow-hidden">
        <Button
          type="button"
          text="Go back"
          onClick={handleGoBack}
          data-test-id="backButton"
          variant={BUTTON_VARIANT.LOGIN}
          shouldGrow
        />
      </div>
      <div className="text-center padding-12-top">
        <span>
          <span>Please read our</span>
          <Link
            data-test-id="loginProviders__privacyPolicyLink"
            to="https://www.quinyx.com/privacy#quinyx-application"
            className="link-primary padding-1-left"
            target="_blank"
            external>
            Privacy Policy
          </Link>
        </span>
      </div>
    </AuthPageWrapper>
  );
};

export default CheckEmailMessage;
