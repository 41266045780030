import React, { useEffect, useRef } from 'react';

import classNames from 'classnames';

import { LegacyInput } from '@shared/components/legacy/LegacyInput';
import LegacyDiv from '@shared/components/LegacyDiv';

import './index.scss';

/**
 * @param {Object} props
 * @param {number} [props.size = 6] - number of input fields for characters.
 * @param {string} [props.pattern = ^[A-Za-z0-9]*$] - RegExp for allowed characters to use in inputs
 * @param {function} props.onChange - onChange handler callback function
 * @param {'text' | 'password'} [props.type = 'textbox'] - Type of the input
 * @param {CSSProperties} [props.inputStyle] - CSSProperties for the inputs
 * @param {CSSProperties} [props.containerStyle] - CSSProperties for the container
 * @param {string} [props.inputClassName] - Classname for the inputs
 * @param {string} [props.containerClassName] - Classname for the container
 */

const CodeInput = ({
  size = 6,
  pattern = '^[A-Za-z0-9]*$',
  onChange,
  type = 'text',
  inputStyle,
  containerStyle,
  inputClassName,
  containerClassName,
  disabled,
}) => {
  const inputsRef = useRef([]);

  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);

  const sendResult = () => {
    const res = inputsRef.current.map((input) => input.value).join('');
    onChange(res);
  };

  const handleOnChange = (e) => {
    if (e.target.value.match(pattern)) {
      if (e.target.nextElementSibling !== null) {
        e.target.nextElementSibling.focus();
      }
    } else {
      e.target.value = '';
    }
    sendResult();
  };

  const handleOnKeyDown = (e) => {
    const { key, target } = e;
    if (key !== 'Backspace') return;
    const previousInputExist = target.previousElementSibling !== null;
    const previousInput = target.previousElementSibling;
    const inputHasValue = target.value === '';

    if (inputHasValue && previousInputExist) {
      if (previousInputExist) {
        previousInput.focus();
        e.preventDefault();
      }
    } else {
      target.value = '';
    }
    sendResult();
  };

  const handleOnFocus = ({ target }) => {
    target.select();
  };

  const handleOnPaste = (e) => {
    const value = e.clipboardData.getData('Text');
    if (value.match(pattern)) {
      for (let i = 0; i < size && i < value.length; i += 1) {
        inputsRef.current[i].value = value.charAt(i);
        if (inputsRef.current[i].nextElementSibling !== null) {
          inputsRef.current[i].nextElementSibling.focus();
        }
      }
      sendResult();
    }
    e.preventDefault();
  };

  return (
    <LegacyDiv
      className={classNames(containerClassName, 'codeInput__container')}
      style={containerStyle}>
      {[...Array(size)].map((_v, i) => (
        <LegacyInput
          id={i}
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          onFocus={handleOnFocus}
          onPaste={handleOnPaste}
          type={type}
          ref={(el) => {
            inputsRef.current[i] = el;
          }}
          maxLength={1}
          className={classNames(inputClassName, 'codeInput__inputs')}
          style={inputStyle}
          autoComplete="off"
          disabled={disabled}
        />
      ))}
    </LegacyDiv>
  );
};

export default CodeInput;
