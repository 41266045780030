import React from 'react';

const Error = (props) => {
  const { fill = 'none' } = props;
  return (
    <svg width={20} height={20} {...props}>
      <g fill={fill} fillRule="evenodd">
        <circle cx={10} cy={10} r={9} />
        <path
          d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-1a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm0-15a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V5a1 1 0 0 1 1-1zm0 12a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
          fill="#F97150"
        />
      </g>
    </svg>
  );
};

export default Error;
