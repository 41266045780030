import z from '@shared/utils/zod';

export const convertHoursTo12 = (hours: string) => {
  const divisor = Number(hours) % 12;
  return divisor ? divisor.toString().padStart(2, '0') : '12';
};

export const convertHoursTo24 = (hours: string, isAm: boolean) => {
  const divisor = Number(hours) % 12;
  return (isAm ? divisor : divisor + 12).toString().padStart(2, '0');
};

export const constructValue = (
  hours: string,
  minutes: string,
  isAm: boolean,
  is12HoursLocale: boolean,
) => {
  const ampm = is12HoursLocale ? ` ${isAm ? 'AM' : 'PM'}` : '';
  return `${hours}:${minutes}${ampm}`;
};

export const checkIsAm = (hours: string) => Number(hours) < 12;

export const flipHoursAmPm = (hours: string) => {
  const numericHours = Number(hours);
  return ((numericHours + 12) % 24).toString().padStart(2, '0');
};

export const getSegment = ({ selectionStart }: HTMLInputElement) => {
  if (!selectionStart || selectionStart < 3) return 0;
  if (selectionStart < 6) return 1;
  return 2;
};

const h12Pattern = '0[1-9]|1[0-2]';
const h24Pattern = '[01][0-9]|2[0-3]';
const mPattern = '[0-5]\\d';
const ampmPattern = '[ap]\\.?m';
const fullPatternH12 = `(${h12Pattern}):(${mPattern}) (${ampmPattern})`;
const fullPatternH24 = `(${h24Pattern}):(${mPattern})`;

const fullRegExpH12 = new RegExp(fullPatternH12, 'i');
const fullRegExpH24 = new RegExp(fullPatternH24);
const hours24RegExp = new RegExp(`^${h24Pattern}$`);
const hours12RegExp = new RegExp(`^${h12Pattern}$`);
const minutesRegExp = new RegExp(`^${mPattern}$`);

const getHours = (hours: string, ampm: string | undefined) => {
  if (!ampm) return hours;
  const firstChar = z.string().parse(ampm[0]);
  return convertHoursTo24(hours, firstChar.toLowerCase() === 'a');
};

export const parseFullTime = (value: string) => {
  const match = value.match(fullRegExpH12) || value.match(fullRegExpH24);

  if (!match) return null;

  const [, hours, minutes, ampm] = match;

  if (!hours) throw new Error('Hours is required');

  return [getHours(hours, ampm), minutes] as const;
};

export const isMinutes = (value: string) => minutesRegExp.test(value);
export const isHours = (value: string, is12HoursLocale = false) => {
  return (is12HoursLocale ? hours12RegExp : hours24RegExp).test(value);
};

export const calcStep = (current: string, divider: 24 | 60, step: number) => {
  return ((Number(current) + divider + step) % divider)
    .toString()
    .padStart(2, '0');
};

export const correctValues = (
  hours: string,
  minutes: string,
  { is12HoursLocale, isAm }: { is12HoursLocale: boolean; isAm: boolean },
) => {
  // first check impossible ampm case of 00 hours
  let correctedHours = is12HoursLocale && hours === '00' ? '12' : hours;
  // second check impossible hours if over 24 || 12
  if (!isHours(hours, is12HoursLocale)) {
    correctedHours = is12HoursLocale ? '12' : '23';
  }
  const correctedHours24 = is12HoursLocale
    ? convertHoursTo24(correctedHours, isAm)
    : correctedHours;

  const correctedMinutes = isMinutes(minutes) ? minutes : '59';

  return [correctedHours24, correctedMinutes] as const;
};

export const adjustMinutesToStep = (minutes: string, step: number) => {
  if (step < 2) return minutes;
  return String(Math.floor(Number(minutes) / step) * step).padStart(2, '0');
};

export const getDraftHours = (hours24: string, is12HoursLocale: boolean) => {
  return is12HoursLocale ? convertHoursTo12(hours24) : hours24;
};
