import { useContext } from 'react';

import { LanguageContext } from '../../Context';

const useLanguage = () => {
  const language = useContext(LanguageContext);
  return language;
};

export default useLanguage;
