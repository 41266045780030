import React from 'react';

import { ssoLogoutApi } from '@shared/api/authentication';
import AuthPageWrapper from '@shared/components/AuthPageWrapper';
import LegacyDiv from '@shared/components/LegacyDiv';
import Link from '@shared/components/Link';

import { useFetch } from '../hooks/api';

const SsoLogout = () => {
  useFetch(ssoLogoutApi, []);
  sessionStorage.clear();
  return (
    <AuthPageWrapper>
      <LegacyDiv className="flex-center">
        <h3>Single sign out initiated</h3>
        <h4>If you wound up here by mistake follow link</h4>

        <Link to="/" className="margin-4-top auth-page-link">
          Back to regular login
        </Link>
      </LegacyDiv>
    </AuthPageWrapper>
  );
};

export default SsoLogout;
