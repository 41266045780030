import React from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';

import AuthPageWrapper from '../../../components/AuthPageWrapper';
import Button from '../../../components/legacy/Button';
import Link from '../../../components/Link';
import { parseQueryString } from '../../../utils/route';

const SsoLogin = ({ location: { search } }) => {
  const { seo } = parseQueryString(search);

  return (
    <AuthPageWrapper>
      <LegacyDiv className="flex-center">
        <h3>Single sign on</h3>
        {seo ? (
          <Link
            to={`/sso/login/${seo}`}
            external
            className="margin-8-top margin-8-bottom">
            <Button label="Login" auth fluid />
          </Link>
        ) : (
          <>
            <LegacyDiv className="margin-8-top">No SEO specified</LegacyDiv>
            <LegacyDiv className="margin-8-top">
              Contact your IT support for further assistance.
            </LegacyDiv>
          </>
        )}
        <Link to="/" className="margin-4-top auth-page-link">
          Back to regular login
        </Link>
      </LegacyDiv>
    </AuthPageWrapper>
  );
};

export default SsoLogin;
