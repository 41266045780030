import React from 'react';

const Star = ({ height = 28, width = 28, fill = 'none', ...rest }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill={fill}
      {...rest}>
      <path
        d="M12.573 2.009c.45-1.382 2.405-1.382 2.854 0L17.34 7.9A2.5 2.5 0 0019.72 9.63h6.196c1.453 0 2.057 1.86.881 2.713l-5.012 3.642a2.5 2.5 0 00-.908 2.795l1.914 5.893c.45 1.382-1.132 2.53-2.308 1.677l-5.012-3.642a2.5 2.5 0 00-2.94 0l-5.012 3.642c-1.176.854-2.757-.295-2.308-1.677l1.914-5.893a2.5 2.5 0 00-.908-2.795l-5.012-3.642C.028 11.488.632 9.63 2.085 9.63h6.196A2.5 2.5 0 0010.66 7.9l1.914-5.892z"
        stroke="#C9D1D0"
      />
    </svg>
  );
};

export default Star;
