import React from 'react';

import { bool, node, oneOf, string } from 'prop-types';

import LegacyDiv from '@shared/components/LegacyDiv';

import './index.scss';

interface FeedbackProps {
  children: React.ReactNode;
  open?: boolean;
  type?: 'error' | 'info';
  className?: string;
}

const Feedback = ({
  children,
  open = true,
  type = 'error',
  className = '',
}: FeedbackProps) =>
  open ? (
    <LegacyDiv
      className={`feedback-content ${type} margin-1-top margin-4-bottom ${className}`}
      data-test-id="feedbackContentDiv">
      {children}
    </LegacyDiv>
  ) : null;

Feedback.propTypes = {
  children: node,
  open: bool,
  type: oneOf(['error', 'info']),
  className: string,
};

export default Feedback;
