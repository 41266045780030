import { GroupTypes, Timeframes } from '@shared/constants';
import { TGroupTypeValues, TTimeframesValues } from '@shared/constants/types';
import { isNil } from '@shared/utils';
import { format } from '@shared/utils/time';
import { DATE_FORMAT } from '@shared/utils/time/constants';

import { PROPERTY } from '../events/schedule/constants';

const groupTypeIdToString = (groupTypeId: TGroupTypeValues) => {
  switch (groupTypeId) {
    case GroupTypes.DOMAIN:
      return 'domain';
    case GroupTypes.DISTRICT:
      return 'district';
    case GroupTypes.UNIT:
      return 'unit';
    case GroupTypes.SECTION:
      return 'section';
    default:
      return undefined;
  }
};

function getDatePeriod(
  timeframe: TTimeframesValues,
  start: string,
  end: string,
  tcDate: string | (string | null)[] | null | undefined,
): Record<'datePeriod', (string | null)[]>;
function getDatePeriod(
  timeframe: TTimeframesValues,
  start: string,
  end: string,
  tcDate?: null | undefined,
): Record<'datePeriod' | 'from_date' | 'to_date', string>;
function getDatePeriod(
  timeframe: TTimeframesValues,
  start: string,
  end: string,
  tcDate: string | (string | null)[] | null | undefined,
) {
  const result: Record<
    string,
    string | (string | null)[] | { start: string; end: string }
  > = {};
  if (!isNil(tcDate)) {
    result[PROPERTY.DATE_PERIOD] = tcDate;
  } else {
    const isSingleDay = timeframe === Timeframes.SINGLEDAY;
    result[PROPERTY.DATE_PERIOD] = isSingleDay ? start : { start, end };
    result[PROPERTY.FROM_DATE] = format(start, DATE_FORMAT);
    result[PROPERTY.TO_DATE] = format(end, DATE_FORMAT);
  }
  return result;
}

export { groupTypeIdToString, getDatePeriod };
