import React, { FC, ReactNode } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import LegacyDiv from '@shared/components/LegacyDiv';

import './index.scss';

type FormGroupProps = {
  children: ReactNode;
  feedback?: ReactNode;
  className?: string;
  feedbackType?: 'invalid' | 'info' | string;
  'data-test-id'?: string;
};

const FormGroup: FC<FormGroupProps> = ({
  children,
  feedback,
  className,
  feedbackType = 'invalid',
  'data-test-id': dataTestId,
}) => (
  <LegacyDiv
    data-test-id={dataTestId}
    className={classNames('form-group', className, {
      invalid: feedbackType === 'invalid' && feedback,
      'feedback-info': feedbackType === 'info' && feedback,
    })}>
    {children}
    <LegacyDiv className="feedback">{feedback}</LegacyDiv>
  </LegacyDiv>
);

FormGroup.propTypes = {
  children: PropTypes.node,
};

export default FormGroup;
