import React, { useEffect, useState } from 'react';

import { colors } from '@shared/colors';
import LegacyDiv from '@shared/components/LegacyDiv';

import useTranslate from '../hooks/useTranslate';

import styles from './index.module.scss';

export interface AdvancedPasswordProps {
  passwordStrengthLevel?: string | undefined | null;
  translatable?: boolean;
}

export const STRENGTH = Object.freeze({
  WEAK: 'Weak',
  FAIR: 'Fair',
  GOOD: 'Good',
  STRONG: 'Strong',
  VERY_STRONG: 'Very strong',
});
const STRENGTH_DESCRIPTION = Object.freeze({
  HIGH: 'Your password is great. Nice work!',
  DEFAULT:
    'Password must be at least 12 characters long and contain at least 2 numbers (0-9) and 2 letters (A-Z)',
});
const STRENGTH_DESCRIPTION_TRANSLATABLE = Object.freeze({
  HIGH: 'people.setNewPassword.great',
  DEFAULT: 'people.setNewPassword.highLevel',
});

const getStyle = (passwordStrengthLevel: string | undefined | null) => {
  switch (passwordStrengthLevel) {
    case STRENGTH.WEAK:
      return { backgroundColor: colors.red_01, width: '20%' };
    case STRENGTH.FAIR:
      return { backgroundColor: colors.red_01, width: '40%' };
    case STRENGTH.GOOD:
      return { backgroundColor: colors.yellow_02, width: '60%' };
    case STRENGTH.STRONG:
      return { backgroundColor: colors.green_02, width: '80%' };
    case STRENGTH.VERY_STRONG:
      return { backgroundColor: colors.green_02, width: '100%' };
    default:
      return { backgroundColor: colors.gray_05, width: '20%' };
  }
};
const getDescription = (
  passwordStrengthLevel: string | undefined | null,
  translatable: boolean | undefined,
) => {
  switch (passwordStrengthLevel) {
    case STRENGTH.VERY_STRONG:
      return translatable
        ? STRENGTH_DESCRIPTION_TRANSLATABLE.HIGH
        : STRENGTH_DESCRIPTION.HIGH;
    default:
      return translatable
        ? STRENGTH_DESCRIPTION_TRANSLATABLE.DEFAULT
        : STRENGTH_DESCRIPTION.DEFAULT;
  }
};

const AdvancedPasswordMeter: React.FC<AdvancedPasswordProps> = ({
  passwordStrengthLevel,
  translatable,
}) => {
  const [glide, setGlide] = useState(false);
  const translate = useTranslate();

  useEffect(() => {
    setGlide(true);
    const timer = setTimeout(() => {
      setGlide(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [passwordStrengthLevel]);
  if (!passwordStrengthLevel) return null;

  return (
    <>
      <LegacyDiv
        data-test-id="advancedPassword"
        className={styles.advancedPassword}>
        <LegacyDiv className={styles.advancedPasswordMeterWrapper}>
          <LegacyDiv className={styles.advancedPasswordMeterLineWrapper}>
            <LegacyDiv className={styles.advancedPasswordMeterLine} />
            <LegacyDiv className={styles.advancedPasswordMeterLine} />
            <LegacyDiv className={styles.advancedPasswordMeterLine} />
            <LegacyDiv className={styles.advancedPasswordMeterLine} />
            <LegacyDiv className={styles.advancedPasswordMeterLine} />
          </LegacyDiv>
          <LegacyDiv
            className={` ${styles.advancedPasswordMeter} ${
              glide ? styles.gliding : ''
            }`}
            style={getStyle(passwordStrengthLevel)}
          />
        </LegacyDiv>
        <LegacyDiv
          data-test-id="advancedPasswordLabel"
          className={styles.advancedPasswordMeterLabel}
          style={{
            color: getStyle(passwordStrengthLevel).backgroundColor,
          }}>
          {translate(passwordStrengthLevel)}
        </LegacyDiv>
      </LegacyDiv>
      <LegacyDiv
        data-test-id="advancedPasswordMeterDescription"
        className={styles.advancedPasswordMeterDescription}>
        {translate(getDescription(passwordStrengthLevel, translatable))}
      </LegacyDiv>
    </>
  );
};

export default AdvancedPasswordMeter;
