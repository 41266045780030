import useLanguage from '../useLanguage';

const translate = (defaultDictionary, dictionary, key) =>
  (dictionary && dictionary[key]) ||
  (defaultDictionary && defaultDictionary[key]) ||
  key;

const useTranslatedText = (values, property) => {
  const { dictionary, defaultDictionary } = useLanguage();

  if (Array.isArray(values)) {
    return values.map((value) => {
      const translatedText = translate(
        defaultDictionary,
        dictionary,
        property ? value[property] : value,
      );
      return property
        ? { ...value, [property]: translatedText }
        : translatedText;
    });
  }
  return translate(defaultDictionary, dictionary, values);
};

export default useTranslatedText;
