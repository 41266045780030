import { useRef } from 'react';

const useDebounce = (func, time = 250) => {
  const interval = useRef();

  return (...args) => {
    clearTimeout(interval.current);
    interval.current = setTimeout(() => {
      interval.current = null;
      func(...args);
    }, time);
  };
};

export default useDebounce;
