import React, { useEffect, useState } from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';

import { get, post } from '../../../../ManagerPortal/api';
import { getRequestUrl } from '../../../api/utils';
import Button from '../../../components/ButtonLogin';
import { BUTTON_VARIANT } from '../../../components/ButtonLogin/constants';
import Form from '../../../components/Form';
import FormControl from '../../../components/Form/FormControl';
import FormGroup from '../../../components/Form/FormGroup';
import Label from '../../../components/Form/Label';
import Link from '../../../components/Link';
import Translate from '../../../components/Translate';
import { PASSWORD } from '../../../constants';
import {
  checkPasswordSecurityLevel,
  isRequired,
} from '../../../utils/validators';
import {
  createValidator,
  validateAll,
} from '../../../utils/validators/validator-model';

const validators = {
  password: [createValidator(isRequired, 'You need to enter a password ;)')],
};

const messageValidations = {
  'reset.password.reuse.not.allowed':
    'It is not allowed to reuse an old password.',
  'reset.password.too.short': 'New password is too short.',
  'reset.password.complexity.too.low': 'Password complexity is too low.',
  'reset.password.hash.invalid': 'This link is no longer valid.',
};

const defaultErrorMessage = 'Something went wrong, please try again.';

const getErrorMessage = ({ body: { errorCode } = {} } = {}) => {
  if (errorCode && messageValidations[errorCode])
    return messageValidations[errorCode];

  return defaultErrorMessage;
};

const DefaultPassword = ({ hash }) => {
  const [password, setPassword] = useState({ value: '' });
  const [confirmPassword, setConfirmPassword] = useState({ value: '' });
  const [hashStatus, setHashStatus] = useState('ERROR');
  const [hashError, setHashError] = useState(
    messageValidations['reset.password.hash.invalid'],
  );
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!hash) {
      return;
    }

    const requestUrl = getRequestUrl(
      'validateUpdatepasswordHash',
      {},
      { hash },
    );

    get(requestUrl, 'VALIDATE_HASH')
      .then(() => {
        setHashStatus('SUCCESS');
      })
      .catch((hashValidationError) => {
        setHashStatus('ERROR');
        setHashError(getErrorMessage(hashValidationError));
      });
  }, [hash]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { value: passwordValue } = password;
    const { value: confirmPasswordValue } = confirmPassword;
    const { password: passwordValidators } = validators;
    const validationMessage = validateAll(passwordValidators, passwordValue);

    if (validationMessage) {
      setPassword({ value: passwordValue, error: validationMessage });
      return;
    }

    if (passwordValue !== confirmPasswordValue) {
      setConfirmPassword({
        value: confirmPasswordValue,
        error: 'Password does not match.',
      });
      return;
    }

    if (!checkPasswordSecurityLevel.high.test(passwordValue)) {
      setConfirmPassword({
        value: confirmPasswordValue,
        error:
          'Password must be at least 12 characters long and contain at least 2 numbers (0-9) and 2 letters (A-Z).',
      });
      return;
    }

    setLoading(true);
    const requestUrl = getRequestUrl('updatepassword');
    post(requestUrl, { password: passwordValue, hash }, 'UPDATE_PASS')
      .then(() => {
        setForm({ success: 'Your password has been updated.' });
      })
      .catch((validationError) => {
        setForm({ error: getErrorMessage(validationError) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const {
      target: { id, value },
    } = e;
    if (id === 'password') {
      setPassword({ value });
    } else if (id === 'confirmPassword') {
      setConfirmPassword({ value });
    }
  };

  const {
    form: { error: formError, success } = {},
    password: { value: passwordValue, error: passwordError },
    confirmPassword: {
      value: confirmPasswordValue,
      error: confirmPasswordError,
    },
  } = {
    form,
    password,
    confirmPassword,
  };

  return (
    <>
      {hashStatus === 'SUCCESS' && (
        <>
          {success ? (
            <LegacyDiv className="text-center">
              <h4>
                <Translate>{success}</Translate>
              </h4>
              <Link to="/" className="margin-4-top" data-test-id="login-link">
                <Button
                  text={<Translate>Click here to log in</Translate>}
                  shouldGrow
                  variant={BUTTON_VARIANT.PRIMARY}
                  data-test-id="login-button"
                />
              </Link>
            </LegacyDiv>
          ) : (
            <>
              <LegacyDiv className="flex-center margin-4-bottom">
                <h1 className="login-header">
                  <Translate>Create new password</Translate>
                </h1>
              </LegacyDiv>
              <Form
                className="flex flex-one flex-column flex-space-between"
                onSubmit={handleSubmit}>
                <LegacyDiv className="flex-one">
                  <FormGroup
                    data-test-id="passwordFormGroup"
                    feedback={
                      (passwordError || formError) && (
                        <span className="font__xSmallRegular color-warning">
                          <Translate>{passwordError || formError}</Translate>
                        </span>
                      )
                    }>
                    <Label htmlFor="email">
                      <Translate>
                        Please create a new password to better secure your
                        account.
                      </Translate>
                    </Label>
                    <FormControl
                      id="password"
                      type="password"
                      value={passwordValue}
                      onChange={handleChange}
                      data-test-id="passwordInput"
                      maxLength={PASSWORD.MAX_LENGTH}
                    />
                  </FormGroup>
                  <FormGroup
                    data-test-id="confirmPasswordFormGroup"
                    feedback={
                      confirmPasswordError ? (
                        <span className="font__xSmallRegular color-warning">
                          <Translate>{confirmPasswordError}</Translate>
                        </span>
                      ) : (
                        <span className="font__xSmallRegular">
                          <Translate>
                            Password must be at least 12 characters long and
                            contain at least 2 numbers (0-9) and 2 letters
                            (A-Z).
                          </Translate>
                        </span>
                      )
                    }>
                    <Label>Confirm password</Label>
                    <FormControl
                      data-test-id="confirmPasswordInput"
                      id="confirmPassword"
                      type="password"
                      value={confirmPasswordValue}
                      onChange={handleChange}
                      maxLength={PASSWORD.MAX_LENGTH}
                    />
                  </FormGroup>
                </LegacyDiv>

                <Button
                  text="Update password"
                  data-test-id="updatePasswordButton"
                  shouldGrow
                  isLoading={loading}
                  variant={BUTTON_VARIANT.LOGIN}
                />
              </Form>
              <LegacyDiv className="text-center padding-3-top">
                <span>
                  <span>Please read our</span>
                  <Link
                    data-test-id="privacyPolicyLink"
                    to="https://www.quinyx.com/privacy#quinyx-application"
                    className="link-primary padding-1-left"
                    target="_blank"
                    external>
                    Privacy Policy
                  </Link>
                </span>
              </LegacyDiv>
            </>
          )}
        </>
      )}
      {hashStatus === 'ERROR' && (
        <LegacyDiv className="text-center">
          <h4>
            <Translate>{hashError}</Translate>
          </h4>
          <LegacyDiv className="text-center">
            <Link
              data-test-id="page-x"
              className="auth-page-link"
              to="/resetpassword">
              <Translate>Reset password</Translate>
            </Link>
          </LegacyDiv>
        </LegacyDiv>
      )}
    </>
  );
};

export default DefaultPassword;
