import React from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';

import QlogoAndName from '../Icons/QlogoAndName';
import AuthPageRight from './AuthPageRight';

import './index.scss';

const AuthPageWrapper = ({ children }) => (
  <LegacyDiv className="auth-page__wrapper">
    <LegacyDiv className="auth-page-logo position-absolute">
      <QlogoAndName width="133px" height="35px" />
    </LegacyDiv>
    <LegacyDiv className="auth-page-left flex-center">
      <LegacyDiv className="auth-page-content">{children}</LegacyDiv>
    </LegacyDiv>
    <LegacyDiv className="auth-page-right flex-center">
      <AuthPageRight />
    </LegacyDiv>
  </LegacyDiv>
);

export default AuthPageWrapper;
