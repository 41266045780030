import React, { ReactNode } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { StrictOmit } from '@shared/types';

import LoadingIcon from '../Icons/LoadingIcon';
import Translate from '../Translate';
import { BUTTON_VARIANT } from './constants';

import './index.scss';

import { LegacyStyleButton } from '../legacy/LegacyStyleButton';

interface CoreButtonProps<T extends React.ElementType> {
  /** Component that will be rendered instead of default <button/> */
  as?: T;
  /** Variant of button styles to be applied, import BUTTON_VARIANT constant */
  variant?: BUTTON_VARIANT;
  /** Loading state of button (button will get disabled also if it's loading) */
  isLoading?: boolean;
  /** Disabled state of button */
  isDisabled?: boolean;
  /** Should button grow to 100% width */
  shouldGrow?: boolean;
  /** ID for automation tests */
  'data-test-id'?: string | number;
  /** Text inside button */
  text: string;
  /** Icon to be shown with text */
  icon?: ReactNode;
}

export type ButtonProps<T extends React.ElementType> = CoreButtonProps<T> &
  StrictOmit<React.ComponentPropsWithoutRef<T>, keyof CoreButtonProps<T>>;

const Button = <T extends React.ElementType = 'button'>({
  as,
  variant = BUTTON_VARIANT.PRIMARY,
  isLoading,
  isDisabled,
  shouldGrow,
  'data-test-id': dataTestId = 'button',
  text,
  icon,
  ...props
}: ButtonProps<T>) => {
  // we create `Component` variable instead of defaulting `as` cause of incorrect TS compiling for default here
  const Component = as || LegacyStyleButton;
  return (
    <Component
      {...props}
      data-test-id={dataTestId}
      className={classNames('componentsButton', {
        componentsButton_primary: variant === BUTTON_VARIANT.PRIMARY,
        componentsButton_secondary: variant === BUTTON_VARIANT.SECONDARY,
        componentsButton_negative: variant === BUTTON_VARIANT.NEGATIVE,
        componentsButton_login: variant === BUTTON_VARIANT.LOGIN,
        componentsButton_loginSecondary:
          variant === BUTTON_VARIANT.LOGIN_SECONDARY,
        'width-100': shouldGrow,
      })}
      disabled={isDisabled || isLoading}>
      {isLoading && (
        <span
          className={classNames(
            'position-absolute bottom-0 top-0 left-0 right-0 flex flex-justify-center flex-items-center',
            {
              componentsButton__loader_secondary:
                variant === BUTTON_VARIANT.SECONDARY,
            },
          )}>
          <LoadingIcon />
        </span>
      )}

      <span
        className={classNames(
          'flex flex-row flex-justify-center flex-items-center width-100',
          { 'visibility-hidden': isLoading },
        )}>
        <span
          className={classNames('width-100 d-block overflow-ellipsis', {
            'padding-1-right': !!icon,
          })}>
          <Translate>{text}</Translate>
        </span>
        {icon}
      </span>
    </Component>
  );
};

type Variant = (typeof BUTTON_VARIANT)[keyof typeof BUTTON_VARIANT];

Button.propTypes = {
  as: PropTypes.elementType,
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANT) as Variant[]),
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  'data-test-id': PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  icon: PropTypes.element,
  shouldGrow: PropTypes.bool,
};

export default Button;
