import { MultiValue, OnChangeValue, Options, SingleValue } from './types';

export function isArray<T>(arg: T | readonly T[]): arg is readonly T[] {
  return Array.isArray(arg);
}

export const findByValue = <IsMulti, Value>(
  value: Value,
  options: Options<Value>,
  isMulti: IsMulti,
) => {
  const values = isArray(value) ? value : [value];
  const newSelectedValues = options.filter((option) =>
    values.includes(option.value),
  );
  return isMulti ? newSelectedValues : newSelectedValues[0];
};

export function singleValueAsValue<Value, IsMulti extends boolean>(
  singleValue: SingleValue<Value>,
): OnChangeValue<Value, IsMulti> {
  return singleValue as OnChangeValue<Value, IsMulti>;
}

export function multiValueAsValue<Value, IsMulti extends boolean>(
  multiValue: MultiValue<Value>,
): OnChangeValue<Value, IsMulti> {
  return multiValue as OnChangeValue<Value, IsMulti>;
}
