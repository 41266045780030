import { isEmbeddedInApp } from '@shared/utils/webView';

const TRACKER_SETTING = Object.freeze({
  APP_ID: 'neo-frontend',
  PLATFORM: isEmbeddedInApp() ? 'Mobile' : 'web',
  TRACKER_NAMESPACE: 'neoWebTracker',
});

// format of properties required by tracker
const DATA_PROPERTY = Object.freeze({
  CUSTOMER_ID: 'customerid',
  GROUP_ID: 'groupid',
  USER_ID: 'userid',
  GROUP_LEVEL: 'grouplevel',
  SHARD_ID: 'shardid',
  APP_LANG: 'applang',
});

// format of properties coming from API as a config object
const CONFIG_PROPERTY = Object.freeze({
  SHARD_ID: 'shard_id',
});

export { TRACKER_SETTING, DATA_PROPERTY, CONFIG_PROPERTY };
