import { useEffect, useRef, useState } from 'react';

export const useFocus = () => {
  const ref = useRef<HTMLInputElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  const checkFocus = () => {
    if (document.activeElement === ref.current) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    window.addEventListener('focus', checkFocus, true);
    window.addEventListener('blur', checkFocus, true);

    return () => {
      window.removeEventListener('focus', checkFocus, true);
      window.removeEventListener('blur', checkFocus, true);
    };
  }, []);

  return [ref, isFocused] as const;
};
