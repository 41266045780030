import React, { ReactElement } from 'react';

import { TFunction, useTranslation } from 'react-i18next';

import { isNil } from '../../../utils';

export type TranslateFn = TFunction<'translation'>;

const useTranslate = () => {
  const { t: translate } = useTranslation();
  return translate;
};

const tagReg = /({{.*?}})/;
const tagValueReg = /^{{(.*)}}$/;
export const useTranslateWithJSX = () => {
  const translate = useTranslate();
  return (
    tKey: string,
    dict: Record<string, ReactElement | string | number>,
  ) => {
    const text = translate(tKey);
    return (
      <>
        {text.split(tagReg).map((chunk, i) => {
          const key = chunk.match(tagValueReg)?.[1]?.trim();
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={i}>
              {key && !isNil(dict[key]) ? dict[key] : chunk}
            </React.Fragment>
          );
        })}
      </>
    );
  };
};

export default useTranslate;
