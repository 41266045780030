import React from 'react';

const WarningIcon = (props) => (
  <svg width={28} height={28} {...props}>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#dc4c66" cx={14.5} cy={14.5} r={10} />
      <rect
        fill="#dc4c66"
        fillRule="nonzero"
        x={13.5}
        y={8}
        width={2}
        height={8}
        rx={1}
      />
      <circle fill="#dc4c66" fillRule="nonzero" cx={14.5} cy={19} r={1} />
    </g>
  </svg>
);

export default WarningIcon;
