import React from 'react';

const Cross = ({
  dataTestId = 'crossIcon',
  color = 'currentColor',
  ...props
}) => (
  <svg
    width={24}
    height={24}
    {...props}
    data-test-id={dataTestId}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.14645 4.14645C4.34171 3.95118 4.65829 3.95118 4.85355 4.14645L12 11.2929L19.1464 4.14645C19.3417 3.95118 19.6583 3.95118 19.8536 4.14645C20.0488 4.34171 20.0488 4.65829 19.8536 4.85355L12.7071 12L19.8536 19.1464C20.0488 19.3417 20.0488 19.6583 19.8536 19.8536C19.6583 20.0488 19.3417 20.0488 19.1464 19.8536L12 12.7071L4.85355 19.8536C4.65829 20.0488 4.34171 20.0488 4.14645 19.8536C3.95118 19.6583 3.95118 19.3417 4.14645 19.1464L11.2929 12L4.14645 4.85355C3.95118 4.65829 3.95118 4.34171 4.14645 4.14645Z"
      fill={color}
    />
  </svg>
);

export default Cross;
