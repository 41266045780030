import React, { forwardRef, InputHTMLAttributes } from 'react';

import classNames from 'classnames';

import { Icon, IconName } from '@shared/components/Icon';
import { LegacyInput } from '@shared/components/legacy/LegacyInput';
import LegacyDiv from '@shared/components/LegacyDiv';

import Translate from '../../Translate';
import { withForm } from '../Form';

import styles from './iconSwitch.module.scss';

/* If checkbox/radio is disabled formData for that checkbox/radio value will be undefined(applies when using Checkbox/Radio component inside Form component)  */
/* https://github.com/react-hook-form/react-hook-form/discussions/4413 */

// HTML spec does not support readOnly attributes on checkbox/radio
// There is a improvisation with the tabIndex attribute so checkbox/radio is not focusable

interface IconSwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  leftIcon?: IconName;
  rightIcon?: IconName;
  label?: string;
  info?: string;
  readOnly?: boolean;
  className?: string;
}

export const IconSwitch = forwardRef<HTMLInputElement, IconSwitchProps>(
  (
    {
      leftIcon = 'hide',
      rightIcon = 'view',
      label,
      info,
      readOnly,
      className: _className,
      ...props
    },
    ref,
  ) => {
    const isDisabled = props.disabled || readOnly;

    return (
      <>
        <label
          className={classNames(
            styles.iconSwitch__wrap,
            isDisabled && styles.iconSwitch__icon_disabled,
          )}>
          <LegacyInput
            {...props}
            disabled={isDisabled}
            tabIndex={isDisabled ? -1 : props.tabIndex}
            ref={ref}
            type="checkbox"
            className={styles.switch__input}
          />
          <LegacyDiv className={styles.iconSwitch__container}>
            <LegacyDiv
              className={classNames(
                styles.iconSwitch__icon,
                styles.iconSwitch__false,
              )}>
              <Icon icon={leftIcon} />
            </LegacyDiv>
            <LegacyDiv
              className={classNames(
                styles.iconSwitch__icon,
                styles.iconSwitch__true,
              )}>
              <Icon icon={rightIcon} />
            </LegacyDiv>
          </LegacyDiv>
          <span className="padding-1-left margin-1-top">
            <Translate>{label}</Translate>
          </span>
        </label>

        {info && (
          <span className={styles.infoMsg}>
            <Translate>{info}</Translate>
          </span>
        )}
      </>
    );
  },
);

IconSwitch.displayName = 'IconSwitch';

export const FormIconSwitch = withForm(IconSwitch);
