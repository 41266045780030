import React from 'react';

import { parseQueryString } from '@shared/utils/route';

import AuthPageWrapper from '../../components/AuthPageWrapper';
import AdvancedPassword from './Advanced';
import Default from './Default';

const UpdatePassword = ({ location }) => {
  const {
    query: { h: hash, token } = parseQueryString(location.search) || {},
  } = location.search;
  // Add this to Default const hashValidation = useValidateUpdatepasswordHash(hash);
  const path = location.pathname;
  const isDefault = path.includes('updatepassword');

  return (
    <AuthPageWrapper>
      {isDefault ? <Default hash={hash} /> : <AdvancedPassword token={token} />}
    </AuthPageWrapper>
  );
};

export default UpdatePassword;
