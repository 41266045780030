import React, { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';

import classNames from 'classnames';
import t from 'prop-types';

import { LegacyInput } from '@shared/components/legacy/LegacyInput';
import LegacyDiv from '@shared/components/LegacyDiv';

import Translate from '../../Translate';
import { withForm } from '../Form';

import styles from './switch.module.scss';

/* If checkbox/radio is disabled formData for that checkbox/radio value will be undefined(applies when using Checkbox/Radio component inside Form component)  */
/* https://github.com/react-hook-form/react-hook-form/discussions/4413 */

// HTML spec does not support readOnly attributes on checkbox/radio
// There is a improvisation with the tabIndex attribute so checkbox/radio is not focusable
interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  info?: string;
  readOnly?: boolean;
  className?: never;
  disabled?: boolean;
  tabIndex?: number;
}

export const Switch = forwardRef(
  (
    { label, info, readOnly, className: _className, ...props }: SwitchProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const isDisabled = props.disabled || readOnly;

    return (
      <LegacyDiv
        className={classNames(
          styles.switch,
          isDisabled && styles.switch__slider_disabled,
        )}>
        <label className={styles.switch__label}>
          <LegacyInput
            {...props}
            tabIndex={isDisabled ? -1 : props.tabIndex}
            ref={ref}
            type="checkbox"
            className={styles.switch__input}
          />
          <LegacyDiv className={styles.switch__slider} />

          <span className="padding-1-left">
            <Translate>{label}</Translate>
          </span>
        </label>

        {info && (
          <span className={styles.switch__infoMsg}>
            <Translate>{info}</Translate>
          </span>
        )}
      </LegacyDiv>
    );
  },
);

Switch.displayName = 'Switch';
Switch.propTypes = {
  label: t.string.isRequired,
  info: t.string,
};

export const FormSwitch = withForm(Switch);

FormSwitch.propTypes = {
  name: t.string.isRequired,
};
