import React from 'react';

import AdvancedPasswordMeter from '@shared/components/AdvancedPasswordMeter';
import Button from '@shared/components/ButtonLogin';
import { BUTTON_VARIANT } from '@shared/components/ButtonLogin/constants';
import { Form, useForm } from '@shared/components/FormElements';
import { handleFormApiError } from '@shared/components/FormElements/Form/utils';
import LegacyDiv from '@shared/components/LegacyDiv';
import Link from '@shared/components/Link';
import { notify } from '@shared/components/Notifications/utils';
import Translate from '@shared/components/Translate';
import { useApi } from '@shared/containers/hooks/api';
import { AdvancedPasswordProps } from '@shared/containers/UpdatePassword/types';

import { fetchPasswordEntropy as fetchPasswordEntropyApi } from '@ManagerPortal/api/authenticationSettings';
import useDebounce from '@ManagerPortal/components/hooks/useDebounce';

import PasswordInput from './PasswordInput';

const UpdatePasswordForm: React.FC<AdvancedPasswordProps> = ({
  onUpdatePassword,
  token,
  entropyForCustomer,
}) => {
  const methods = useForm();

  const [
    {
      data: { passwordStrengthLevel, entropyBits } = {
        passwordStrengthLevel: '',
        entropyBits: 0,
      },
    },
    updatePasswordEntropy,
  ] = useApi(fetchPasswordEntropyApi) || {};
  const updateEntropy = useDebounce(updatePasswordEntropy, 500);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name === 'password') {
      updateEntropy(value);
    }
  };
  const [password] = methods.watch(['password']);
  const [confirmPassword] = methods.watch(['confirmPassword']);

  const onSubmit = ({
    password: p,
    confirmPassword: c,
  }: Record<string, string>) => {
    if (p !== c) {
      methods.setError('confirmPassword', {
        type: 'custom',
        message: 'Password does not match.',
      });
      return;
    }
    onUpdatePassword(p, token)
      .then(() => {
        notify('Password updated!', 'success');
      })
      .catch((e) => {
        handleFormApiError(methods, e);
      });
  };

  return (
    <>
      <LegacyDiv className="flex-center margin-4-bottom">
        <h1 className="login-header">
          <Translate>Create new password</Translate>
        </h1>
      </LegacyDiv>
      <Form methods={methods} onSubmit={onSubmit}>
        <PasswordInput
          name="password"
          label="Please create a new password to better secure your
        account."
          onChange={handlePasswordChange}
        />

        <LegacyDiv className="margin-2-top margin-2-bottom">
          {password && (
            <AdvancedPasswordMeter
              passwordStrengthLevel={passwordStrengthLevel}
            />
          )}
        </LegacyDiv>
        <PasswordInput
          name="confirmPassword"
          label="Confirm password"
          onChange={handlePasswordChange}
        />
        <LegacyDiv className="margin-2-top margin-2-bottom">
          <Button
            text="Update password"
            isDisabled={
              !password ||
              !confirmPassword ||
              entropyBits < entropyForCustomer ||
              password !== confirmPassword
            }
            variant={BUTTON_VARIANT.LOGIN}
          />
        </LegacyDiv>
      </Form>
      <LegacyDiv className="text-center">
        <span>
          <span>Please read our</span>
          <Link
            data-test-id="privacyPolicyLink"
            to="https://www.quinyx.com/privacy#quinyx-application"
            className="link-primary padding-1-left"
            target="_blank"
            external>
            Privacy Policy
          </Link>
        </span>
      </LegacyDiv>
    </>
  );
};

export default UpdatePasswordForm;
