import React from 'react';

const BlockerIcon = (props) => (
  <svg width={200} height={172} viewBox="0 0 200 172" fill="none" {...props}>
    <path
      d="M68.34 136.679c37.742 0 68.339-30.597 68.339-68.34C136.679 30.597 106.082 0 68.339 0 30.597 0 0 30.596 0 68.34c0 37.742 30.596 68.339 68.34 68.339z"
      fill="#fff"
    />
    <path
      d="M106.013 172a72.82 72.82 0 01-33.354-8.103 72.112 72.112 0 01-25.82-22.441l26.62-18.48a39.68 39.68 0 0014.203 12.347 40.072 40.072 0 0018.348 4.461 39.533 39.533 0 0017.859-4.191l14.652 28.753A72.947 72.947 0 01106.013 172z"
      fill="#F2D4D7"
    />
    <path
      d="M92.68 135.709c8.088 0 14.644-6.556 14.644-14.643 0-8.088-6.556-14.644-14.644-14.644-8.087 0-14.643 6.556-14.643 14.644 0 8.087 6.556 14.643 14.643 14.643z"
      fill="#B23D59"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M173.539 94.549c-7.172 0-12.986 5.743-12.986 12.828 0 7.085 5.814 12.828 12.986 12.828 7.172 0 12.986-5.743 12.986-12.828 0-7.085-5.814-12.828-12.986-12.828zm-26.104 12.828c0-14.241 11.687-25.786 26.104-25.786s26.104 11.545 26.104 25.786c0 14.241-11.687 25.786-26.104 25.786s-26.104-11.545-26.104-25.786z"
      fill="#BADDDA"
    />
    <path
      d="M129.055 110.157l-12.976 20.282 64.472 41.247 12.976-20.282-64.472-41.247z"
      fill="#004851"
    />
  </svg>
);

export default BlockerIcon;
