import * as React from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';

// config import is important to let webpack know that all svg should be included
import icons from './config';

import './index.scss';

import { IconProps } from './types';

// Type assertion done to solve incorrect eslint work
// TODO: replace check in warning to "icons[icon as keyof typeof icons] === undefined" when eslint switched to @typescript-eslint/parser (iconsObj should be removed)
const iconsObj: { [key: string]: any } = icons;

const useWarning = (icon: string) => {
  useEffect(() => {
    if (iconsObj[icon] === undefined) {
      // eslint-disable-next-line no-console
      console.warn(
        `"Icon" expects "icon" prop to has one of the following values [${Object.keys(
          icons,
        ).join(', ')}], but got "${icon}"`,
      );
    }
  }, [icon]);
};

export const Icon = React.memo(
  React.forwardRef<SVGSVGElement, IconProps>(
    ({ icon, className, ...restProps }, ref) => {
      useWarning(icon);
      return (
        <svg
          ref={ref}
          width="24px"
          height="24px"
          className={classNames('inlineIcon', className)}
          {...restProps}>
          <use xlinkHref={`#${icon}.svg`} />
        </svg>
      );
    },
  ),
);
