import React, { FC, ReactNode } from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';

import LegacyDiv from '@shared/components/LegacyDiv';

import ActiveIcon from '../../Icons/Active';
import ErrorIcon from '../../Icons/Error';
import InfoIcon from '../../Icons/Info';
import WarningIcon from '../../Icons/Warning';
import Translate from '../../Translate';

import './index.scss';

export type NotificationType = 'info' | 'warning' | 'success' | 'error';

interface NotificationProps {
  type?: NotificationType;
  children?: ReactNode;
  color?: string;
  className?: string;
  withBorder?: boolean;
}

const getIcon = ({
  type,
  color,
}: Pick<NotificationProps, 'type' | 'color'>) => {
  switch (type) {
    case 'info':
      return (
        <InfoIcon
          className="vertical-middle"
          viewBox="4 4 21 21"
          width="20px"
          height="20px"
          color={color}
        />
      );
    case 'success':
      return (
        <ActiveIcon className="vertical-middle" width="20px" height="20px" />
      );
    case 'warning':
      return (
        <WarningIcon
          className="vertical-middle"
          viewBox="4 4 21 21"
          width="20px"
          height="20px"
        />
      );
    case 'error':
      return <ErrorIcon className="vertical-middle" />;
    default:
      return <InfoIcon className="vertical-middle" />;
  }
};

const Notification: FC<NotificationProps> = ({
  type,
  children,
  color,
  className,
  withBorder = false,
}) => (
  <LegacyDiv
    className={classnames('notification', className, {
      notification_withBorder: withBorder,
    })}
    style={color ? { borderColor: color } : undefined}>
    <LegacyDiv className="display-inline-block padding-2-right">
      {getIcon({ type, color })}
    </LegacyDiv>
    <LegacyDiv className="notification__message">
      <Translate>{children}</Translate>
    </LegacyDiv>
  </LegacyDiv>
);

Notification.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'success', 'error']),
  children: PropTypes.node,
  color: PropTypes.string,
  className: PropTypes.string,
  withBorder: PropTypes.bool,
};

export default Notification;
