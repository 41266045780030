import React from 'react';

import classNames from 'classnames';
import propTypes from 'prop-types';

import { StrictOmit } from '@shared/types';

import { Icon, IconName } from '../Icon';
import LoadingIcon from '../Icons/LoadingIcon';
import { LegacyStyleButton } from '../legacy/LegacyStyleButton';
import Translate from '../Translate';

import styles from './index.module.scss';

const VARIANT_STYLES = Object.freeze({
  contained: styles.contained,
  light: styles.light,
  text: styles.text,
});

const COLOR_STYLES = Object.freeze({
  success: styles.success,
  neutral: styles.neutral,
  danger: styles.danger,
});

const SIZE_STYLES = Object.freeze({
  medium: styles.medium,
});

type ButtonVariant = keyof typeof VARIANT_STYLES;
type ButtonColor = keyof typeof COLOR_STYLES;
type ButtonSize = keyof typeof SIZE_STYLES;

interface ButtonBaseCommonProps<T extends React.ElementType> {
  as?: T;
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  children?: React.ReactNode;
  'data-test-id': string;
  className?: never;
  style?: never;
}

type ButtonBaseProps<T extends React.ElementType> = ButtonBaseCommonProps<T> &
  StrictOmit<React.ComponentPropsWithoutRef<T>, keyof ButtonBaseCommonProps<T>>;

export const ButtonBase = <T extends React.ElementType = 'button'>({
  as,
  variant = 'contained',
  color = 'success',
  size = 'medium',
  className: _className,
  style: _style,
  ...props
}: ButtonBaseProps<T>) => {
  const Component = as || LegacyStyleButton;
  return (
    <Component
      {...props}
      className={classNames(
        styles.button,
        VARIANT_STYLES[variant] || VARIANT_STYLES.contained,
        COLOR_STYLES[color] || COLOR_STYLES.success,
        SIZE_STYLES[size] || SIZE_STYLES.medium,
      )}
    />
  );
};

const buttonBaseTypes = {
  variant: propTypes.oneOf(['contained', 'light', 'text']),
  color: propTypes.oneOf(['success', 'neutral', 'danger']),
  size: propTypes.oneOf(['medium']),
  as: propTypes.elementType,
};

ButtonBase.propTypes = {
  ...buttonBaseTypes,
};

export type ButtonProps<T extends React.ElementType> = ButtonBaseProps<T> & {
  text: string;
  startIcon?: IconName;
  endIcon?: IconName;
};

export const Button = <T extends React.ElementType = 'button'>({
  startIcon,
  endIcon,
  text,
  ...props
}: ButtonProps<T>) => {
  return (
    // @ts-expect-error it seems like a quirk in React.createElement related to generic passing
    <ButtonBase {...props}>
      <span className={styles.buttonContent}>
        {startIcon && <Icon icon={startIcon} />}

        <span className={styles.buttonText}>
          <Translate>{text}</Translate>
        </span>

        {endIcon && <Icon icon={endIcon} />}
      </span>
    </ButtonBase>
  );
};

Button.propTypes = {
  text: propTypes.string.isRequired,
  startIcon: propTypes.string,
  endIcon: propTypes.string,
  ...buttonBaseTypes,
};

type LoadingButtonProps<T extends React.ElementType> = ButtonBaseProps<T> & {
  text: string;
  endIcon?: IconName;
  isLoading?: boolean;
};

export const LoadingButton = <T extends React.ElementType = 'button'>({
  endIcon,
  isLoading = false,
  text,
  ...props
}: LoadingButtonProps<T>) => {
  return (
    // @ts-expect-error it seems like a quirk in React.createElement related to generic passing
    <ButtonBase disabled={isLoading} {...props}>
      {isLoading && (
        <span className={styles.loader}>
          <LoadingIcon />
        </span>
      )}

      <span
        className={classNames(
          styles.buttonContent,
          isLoading && styles.isLoading,
        )}>
        <span className={styles.buttonText}>
          <Translate>{text}</Translate>
        </span>

        {endIcon && <Icon icon={endIcon} />}
      </span>
    </ButtonBase>
  );
};

LoadingButton.propTypes = {
  text: propTypes.string.isRequired,
  isLoading: propTypes.bool,
  endIcon: propTypes.string,
  ...buttonBaseTypes,
};

type IconButtonProps<T extends React.ElementType> = ButtonBaseProps<T> & {
  icon: IconName;
};

export const IconButton = <T extends React.ElementType = 'button'>({
  icon,
  disabled,
  ...props
}: IconButtonProps<T>) => {
  return (
    // @ts-expect-error it seems like a quirk in React.createElement related to generic passing
    <ButtonBase disabled={disabled} {...props}>
      <span className={styles.iconBtnContent}>
        <Icon icon={icon} />
      </span>
    </ButtonBase>
  );
};

IconButton.propTypes = {
  icon: propTypes.string.isRequired,
  ...buttonBaseTypes,
};
