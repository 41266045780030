export const DEFAULT_COLORS = [
  '#FFF600',
  '#5AFFB0',
  '#19A9D1',
  '#1F78FF',
  '#510303',
  '#FBD302',
  '#2EE500',
  '#38E8F3',
  '#B70099',
  '#956201',
  '#FBCA73',
  '#C0EC88',
  '#ACEAFB',
  '#F4BCEF',
  '#CCD6E0',
  '#FC9700',
  '#85AD9D',
  '#8FBCFF',
  '#B290FA',
  '#646974',
  '#ED6301',
  '#2E7D5C',
  '#5484C6',
  '#941DF2',
  '#9B0C59',
  '#FF553E',
  '#009B10',
  '#05638B',
  '#5024FF',
  '#B60F01',
  '#F50067',
  '#154F0B',
  '#002197',
  '#573C99',
  '#000000',
];
