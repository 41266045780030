import React, { ReactNode } from 'react';

import classNames from 'classnames';

interface LegacyDivProps {
  children?: ReactNode;
  className?: string;

  // All other properties
  [prop: string]: any;
}

const LegacyDiv = React.forwardRef<HTMLDivElement, LegacyDivProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames('legacyDiv', className || '')}
        {...rest}>
        {children}
      </div>
    );
  },
);

export default LegacyDiv;
