import React from 'react';

const CalendarIcon = ({ width = 18, height = 16, ...rest }) => (
  <svg
    aria-hidden="true"
    width={width}
    height={height}
    viewBox="0 0 18 16"
    fill="none"
    {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8.5C10 8.22386 10.2239 8 10.5 8H14.5C14.7761 8 15 8.22386 15 8.5V12.5C15 12.7761 14.7761 13 14.5 13H10.5C10.2239 13 10 12.7761 10 12.5V8.5ZM11 12V9H14V12H11Z"
      fill="#5F6A7C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 0C12.7761 0 13 0.223858 13 0.5V1H17.5C17.7761 1 18 1.22386 18 1.5V15.5C18 15.7761 17.7761 16 17.5 16H0.5C0.223858 16 0 15.7761 0 15.5V1.5C0 1.22386 0.223858 1 0.5 1H5V0.5C5 0.223858 5.22386 0 5.5 0C5.77614 0 6 0.223858 6 0.5V1H12V0.5C12 0.223858 12.2239 0 12.5 0ZM5 2V2.63333C5 2.90948 5.22386 3.13333 5.5 3.13333C5.77614 3.13333 6 2.90948 6 2.63333V2H12V2.63333C12 2.90948 12.2239 3.13333 12.5 3.13333C12.7761 3.13333 13 2.90948 13 2.63333V2H17V15H1V2H5Z"
      fill="#5F6A7C"
    />
  </svg>
);

export default CalendarIcon;
