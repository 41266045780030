import React, { useEffect, useRef } from 'react';

import Form from '@shared/components/Form';
import Select from '@shared/components/Input/Select';
import { REGIONS } from '@shared/constants';

import { isNil } from '../../../utils';
import { isEmail as isEmailValid } from '../../../utils/validators';
import {
  createValidator,
  validate,
} from '../../../utils/validators/validator-model';
import Button from '../../ButtonLogin';
import { BUTTON_VARIANT } from '../../ButtonLogin/constants';
import FormControl from '../../Form/FormControl';
import FormGroup from '../../Form/FormGroup';
import Label from '../../Form/Label';
import useToggle from '../../hooks/useToggle';

const emailValidator = createValidator(
  isEmailValid,
  'Please enter a valid email',
);

const EmailForm = ({
  onEmailSubmit,
  isLoading,
  email,
  onChangeEmail,
  isError,
  errors,
  isEmail,
  region,
  onRegionChange,
  isSuccessRegion,
}) => {
  const [
    shouldShowValidationError,
    { check: setShowValidationError, uncheck: setHideValidationError },
  ] = useToggle(false);

  const emailValidationError = validate(emailValidator, email);

  const handleEmailChange = ({ target: { value } }) => {
    if (shouldShowValidationError === true) {
      setHideValidationError();
    }

    onChangeEmail(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      return;
    }

    if (isEmail && emailValidationError) {
      setShowValidationError();
      return;
    }

    onEmailSubmit(email);
  };

  const focusRef = useRef();
  useEffect(() => {
    if (isNil(focusRef.current)) {
      return;
    }
    focusRef.current.focus();
  }, []);

  const regionOptions = Object.entries(REGIONS).map(([key, data]) => ({
    value: data.id,
    label: data.name,
    short: key,
  }));

  return (
    <Form
      className="flex flex-one flex-column flex-space-between"
      onSubmit={handleSubmit}>
      <FormGroup
        data-test-id="emailOrProviderInputFormGroup"
        className="loginProviders__emailInput"
        feedback={
          (isError || shouldShowValidationError) && (
            <span className="font__xSmallRegular color-warning">
              {errors.mainMessage || emailValidationError}
            </span>
          )
        }>
        <Label htmlFor="email">Enter your email or your login alias</Label>
        <FormControl
          focusRef={focusRef}
          name="email"
          value={email}
          onChange={handleEmailChange}
          data-test-id="emailOrProviderInput"
          autoComplete="email-quinyx"
        />

        <Label className="margin-2-top" htmlFor="qshard">
          Select region
        </Label>

        {isSuccessRegion && (
          <Select
            label="Qshard"
            name="qshard"
            options={regionOptions}
            onChange={onRegionChange}
            value={region}
            id="qShard"
          />
        )}
      </FormGroup>
      <Button
        text="Continue"
        data-test-id="fetchLoginProvidersButton"
        shouldGrow
        isLoading={isLoading}
        isDisabled={isNil(email) || email === ''}
        variant={BUTTON_VARIANT.LOGIN}
      />
    </Form>
  );
};

export default EmailForm;
