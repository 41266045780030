import React, { FC } from 'react';

import classnames from 'classnames';

import './index.scss';

interface LabelProps {
  htmlFor?: string;
  className?: string;
  required?: boolean;
}

const Label: FC<LabelProps> = ({
  htmlFor,
  children,
  className = null,
  required,
}) => {
  return (
    <label
      className={classnames(className, 'label', {
        'label-required': required,
      })}
      htmlFor={htmlFor}>
      {children}
    </label>
  );
};

export default Label;
