import React from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';

import AuthPageWrapper from '../../../components/AuthPageWrapper';
import Button from '../../../components/legacy/Button';
import Link from '../../../components/Link';
import { parseQueryString } from '../../../utils/route';

const errorMessages = {
  0: 'Failed to login',
  1: 'Saml response is  missing required attribute.',
  2: 'Given seo is not valid.',
  3: 'Employee is not registered in quinyx.',
  4: 'Employee is not active.',
  default: 'An error occurred.',
};

const SsoError = ({ location: { search } }) => {
  const { info = '', seo } = parseQueryString(search);
  const message = errorMessages[info] || errorMessages.default;

  return (
    <AuthPageWrapper>
      <LegacyDiv className="flex-center">
        <h3>Single sign on failed</h3>
        <LegacyDiv className="margin-4-top">{message}</LegacyDiv>
        {seo && (
          <Link to={`/sso/login/${seo}`} external className="margin-8-top">
            <Button label="Try again" auth fluid />
          </Link>
        )}
        <LegacyDiv className="margin-8-top">
          Contact your IT support for further assistance.
        </LegacyDiv>
        <Link to="/" className="margin-4-top auth-page-link">
          Back to regular login
        </Link>
      </LegacyDiv>
    </AuthPageWrapper>
  );
};

export default SsoError;
