import React from 'react';

const Copy = (props) => (
  <svg width={24} height={24} {...props}>
    <g
      stroke="#41494D"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M8 8h12v12H8z" />
      <path d="M7.652 16H4V4h12v3.652M14 12v4m2-2h-4" />
    </g>
  </svg>
);

export default Copy;
