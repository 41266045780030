import React from 'react';

import { ToastContainer } from 'react-toastify';

import CrossIconButton from '../../CrossIconButton';

import 'react-toastify/dist/ReactToastify.css';
import './index.scss';

type OnCrossIconButtonClick = Parameters<typeof CrossIconButton>[0]['onClick'];

const CloseButton = ({
  closeToast,
}: {
  closeToast?: OnCrossIconButtonClick;
}) => (
  <CrossIconButton
    onClick={(...args) => closeToast?.(...args)}
    className="toast-close-button"
  />
);

const NotificationsHolder = () => (
  <ToastContainer
    className="toast-container"
    position="top-right"
    autoClose={5000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    closeButton={<CloseButton />}
  />
);

export default NotificationsHolder;
