import hexToRgbaLib from 'hex-rgba';

import { isNil } from '@shared/utils';

import { HexColor, RgbaColor } from './types';

export * from './index-old';

type RgbaTouple = [number, number, number, number];

export const hexToRgba = (hex?: HexColor, opacity?: number) => {
  return hexToRgbaLib(hex || '#ffffff', opacity || 100) as RgbaColor;
};

const linearize = (channel: number) =>
  channel <= 10 ? channel / 3294 : (channel / 269 + 0.0513) ** 2.4;

export const lightenHex = (color: HexColor, percent: number) =>
  `#${color.replace(/^#/, '').replace(/../g, (channel) => {
    const channelValue = parseInt(channel, 16);
    return `0${Math.round(
      Math.min(
        255,
        Math.max(0, channelValue + (255 - channelValue) * (percent / 100)),
      ),
    ).toString(16)}`.substr(-2);
  })}`;

function assertRgbaTouple(
  arr: (number | undefined)[],
): asserts arr is RgbaTouple {
  if (arr.length !== 4 || arr.some(isNil)) throw new Error('Invalid color');
}

const relativeLuminance = (color: RgbaColor) => {
  const rgbArr = color
    .replace(/[^\d,]/g, '')
    .split(',')
    .map((channel) => parseInt(channel));
  assertRgbaTouple(rgbArr);

  const linearRgbaArr = rgbArr.map(linearize);
  assertRgbaTouple(linearRgbaArr);
  const [r, g, b] = linearRgbaArr;

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const isDark = (color: HexColor) => {
  const colorLuminance = relativeLuminance(hexToRgba(color));
  const whiteLuminance = 1;
  const blackLuminance = 0;

  const whiteContrast = (whiteLuminance + 0.05) / (colorLuminance + 0.05);
  const blackContrast = (colorLuminance + 0.05) / (blackLuminance + 0.05);

  return whiteContrast > blackContrast;
};

export const contrastRatio = (color: HexColor) =>
  isDark(color) ? '#ffffff' : '#000000';
