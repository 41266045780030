import { useContext } from 'react';

import { AppFeedbackModalContext } from '../../../../shared/components/Context';

const useAppFeedbackModal = () => {
  const appFeedback = useContext(AppFeedbackModalContext);
  return appFeedback;
};

export default useAppFeedbackModal;
