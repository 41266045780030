import { isNil } from '..';

export function assertContextHasValue<T>(
  contextValue: T | null | undefined,
  hookName: string,
): asserts contextValue is T {
  if (isNil(contextValue))
    throw new Error(
      `${hookName} was used before it's provider, check that ${hookName} is used inside corresponding provider component`,
    );
}
