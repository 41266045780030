import React from 'react';

const Active = (props) => (
  <svg
    width="10px"
    height="10px"
    viewBox="0 0 10 10"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <title>Active</title>
    <defs />
    <g
      id="status/active"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g id="Group-4">
        <circle id="Oval-4" stroke="#4CC122" cx="5" cy="5" r="4.5" />
        <g
          id="Group-3"
          transform="translate(2.000000, 2.000000)"
          fill="#4CC122">
          <path
            d="M2.59831165,3.99985493 L4.03293329,3.99760041 L4.0342803,4.85474221 L1.7425158,4.85834374 L1.74209252,4.58899726 L1.74116879,0.422330592 L2.59831165,0.422330592 L2.59831165,3.99985493 Z"
            id="Combined-Shape"
            transform="translate(2.887725, 2.640337) scale(-1, 1) rotate(-45.000000) translate(-2.887725, -2.640337) "
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Active;
