import React from 'react';

const CornerFigure = ({ width = 63, height = 63, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 63 60" fill="none" {...rest}>
    <path
      d="M48 60C48 53.6965 46.7584 47.4548 44.3462 41.6312C41.934 35.8076 38.3983 30.5161 33.9411 26.0589C29.4839 21.6017 24.1924 18.066 18.3688 15.6538C12.5452 13.2416 6.30345 12 0 12"
      stroke="#3591A0"
      strokeWidth="23"
    />
    <circle cx="50" cy="24" r="13" fill="#FEE4E0" />
  </svg>
);

export default CornerFigure;
