import React from 'react';

import LegacyDiv from '@shared/components/LegacyDiv';

import Link from '../../Link';

import './index.scss';

const isExternal = (targetUri) => {
  const isNavigation =
    targetUri.includes('/passwordLogin') || targetUri.includes('/multiFactor');
  return !isNavigation;
};

const ProvidersPicker = ({ loginProviders = [] }) => {
  return (
    <LegacyDiv className="flex flex-one overflow-y-auto">
      {loginProviders.map(({ name, targetUri }) => (
        <Link
          data-test-id="providersPicker-link"
          className="providersPicker"
          key={targetUri}
          external={isExternal(targetUri)}
          to={decodeURIComponent(targetUri || '')}>
          {name}
        </Link>
      ))}
    </LegacyDiv>
  );
};

export default ProvidersPicker;
