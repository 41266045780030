import React, { useEffect } from 'react';

import AuthPageWrapper from '@shared/components/AuthPageWrapper';
import Loading from '@shared/components/Loading';
import { parseQueryString } from '@shared/utils/route';

const MobileLogin = ({ location }) => {
  const { query: { token } = parseQueryString(location.search) || {} } =
    location?.search;
  const link = `quinyxapp://login?token=${token}`;

  useEffect(() => {
    window.location.href = link;
  }, [link]);

  return (
    <AuthPageWrapper>
      <h1 className="login-header"> Redirecting to mobile app</h1>
      <Loading className="flex-center" />
    </AuthPageWrapper>
  );
};

export default MobileLogin;
