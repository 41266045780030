import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { LegacyInput } from '@shared/components/legacy/LegacyInput';
import { isNil } from '@shared/utils';
import { mergeRefs } from '@shared/utils/mergeRefs';
import z from '@shared/utils/zod';

import {
  adjustMinutesToStep,
  calcStep,
  checkIsAm,
  constructValue,
  convertHoursTo24,
  correctValues,
  flipHoursAmPm,
  getDraftHours,
  getSegment,
  isHours,
  isMinutes,
  parseFullTime,
} from './utils';

import styles from './index.module.scss';

type Segment = 0 | 1 | 2;

const selectionRanges: Record<Segment, [number, number]> = {
  0: [0, 2],
  1: [3, 5],
  2: [6, 8],
};

const noop = () => {};

interface FieldProps {
  value: string;
  is12HoursLocale: boolean;
  onChange: (value: string) => void;
  minutesStep: number;
  name: string;
  'data-test-id': string;
  disabled?: boolean;
  id?: string;
}

function assertSegment(segment: number): asserts segment is Segment {
  if (segment < 0 || segment > 2) {
    throw new Error('Segment should be 0, 1 or 2');
  }
}

export const TimeInputField = forwardRef(
  (
    { value, onChange, is12HoursLocale, minutesStep, ...restProps }: FieldProps,
    forwardedRef: ForwardedRef<HTMLInputElement>,
  ) => {
    const [hoursExternal, minutesExternal] = z
      .tuple([z.string(), z.string()]) // we need to ensure that we have exactly 2 elements in the tuple
      .rest(z.string()) // rest is needed because sometimes we have 3 elements in the tuple (seconds as well)
      .parse(value.split(':'));

    const isAm = checkIsAm(hoursExternal);
    // We need these states to store draft values - not yet corrected by step, min, max etc.
    const [hours, setHours] = useState(
      getDraftHours(hoursExternal, is12HoursLocale),
    );
    const [minutes, setMinutes] = useState(minutesExternal);
    useEffect(() => {
      setHours(getDraftHours(hoursExternal, is12HoursLocale));
      setMinutes(minutesExternal);
    }, [hoursExternal, minutesExternal, is12HoursLocale]);

    const ref = useRef<HTMLInputElement>(null);

    const lastSegment = is12HoursLocale ? 2 : 1;

    const [isTyping, setIsTyping] = useState(false);
    // we use this ref and layout effect only for ensuring position after hours/minutes state changes
    const lastKnownSegmentRef = useRef<Segment>();

    const selectSegment = useCallback(
      (segment?: number) => {
        if (!ref.current) return;
        let finalSegment = segment ?? getSegment(ref.current);
        if (finalSegment < 0) finalSegment = 0;
        if (finalSegment > lastSegment) finalSegment = lastSegment;

        // When reach this step, finalSegment is guaranteed to be Segment
        assertSegment(finalSegment);

        const range = selectionRanges[finalSegment];
        if (finalSegment !== lastKnownSegmentRef.current) setIsTyping(false);
        lastKnownSegmentRef.current = finalSegment;
        ref.current.setSelectionRange(...range);
      },
      [lastSegment],
    );

    useLayoutEffect(() => {
      if (!ref.current || isNil(lastKnownSegmentRef.current)) return;
      const [newStart] = selectionRanges[lastKnownSegmentRef.current];
      ref.current.selectionStart = newStart;
      selectSegment();
    }, [hours, minutes, isAm, selectSegment]);

    const updateDraft = (newHours = hours, newMinutes = minutes) => {
      if (newHours !== hours) setHours(newHours);
      if (newMinutes !== minutes) setMinutes(newMinutes);
    };

    const dispatchChange = (
      newHours24 = hoursExternal,
      newMinutes = minutesExternal,
    ) => {
      const adjustedMinutes = adjustMinutesToStep(newMinutes, minutesStep);
      updateDraft(getDraftHours(newHours24, is12HoursLocale), adjustedMinutes);
      const newValue = `${newHours24}:${adjustedMinutes}`;
      if (newValue !== value) onChange(newValue);
    };

    const selectSegmentAndDispatchChange = (
      segment: number,
      newHours = hours,
      newMinutes = minutes,
    ) => {
      dispatchChange(
        ...correctValues(newHours, newMinutes, {
          is12HoursLocale,
          isAm,
        }),
      );

      selectSegment(segment);
    };

    const doStep = (isPositiveDirection: boolean) => {
      if (!ref.current) return;
      const currentSegment = getSegment(ref.current);
      lastKnownSegmentRef.current = currentSegment;
      if (currentSegment === 2) {
        dispatchChange(
          flipHoursAmPm(
            is12HoursLocale ? convertHoursTo24(hours, isAm) : hours,
          ),
        );
      } else {
        const isHoursStep = currentSegment === 0;
        const step =
          (isHoursStep ? 1 : minutesStep) * (isPositiveDirection ? 1 : -1);
        const hours24 = is12HoursLocale ? convertHoursTo24(hours, isAm) : hours;
        const newHours = isHoursStep ? calcStep(hours24, 24, step) : hours24;
        const newMinutes = isHoursStep ? minutes : calcStep(minutes, 60, step);
        dispatchChange(newHours, newMinutes);
      }
    };

    const handleTyping = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!ref.current) return;
      const currentSegment = getSegment(ref.current);
      if (currentSegment === 0) {
        // check is it a first digit hit or the second one
        if (!isTyping) {
          const update = `0${e.key}`;
          // if we are typing a digit that can be the first one later, we should wait for the second one
          if (Number(e.key) < (is12HoursLocale ? 2 : 3)) {
            setHours(update);
            setIsTyping(true);
            // if we are saving time without second digit
            const newValue = `${update}:${minutesExternal}`;
            if (newValue !== value) onChange(newValue);
          } else {
            selectSegmentAndDispatchChange(currentSegment + 1, update);
          }
        } else {
          const update = `${hours[1]}${e.key}`;
          selectSegmentAndDispatchChange(currentSegment + 1, update);
        }
      }
      if (currentSegment === 1) {
        // check is it a first digit hit or the second one
        if (!isTyping) {
          const update = `0${e.key}`;
          // if we are typing a digit that can be the first one later, we should wait for the second one
          if (Number(e.key) < 6) {
            setMinutes(update);
            setIsTyping(true);
            // if we are saving time without second digit
            const newValue = `${hoursExternal}:${update}`;
            if (newValue !== value) onChange(newValue);
          } else {
            selectSegmentAndDispatchChange(
              currentSegment + 1,
              undefined,
              update,
            );
          }
        } else {
          const update = `${minutes[1]}${e.key}`;
          selectSegmentAndDispatchChange(currentSegment + 1, undefined, update);
        }
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!ref.current) return;
      const currentSegment = getSegment(ref.current);
      switch (e.key) {
        case 'ArrowLeft': {
          e.preventDefault();
          selectSegmentAndDispatchChange(currentSegment - 1);
          break;
        }
        case 'ArrowRight': {
          e.preventDefault();
          selectSegmentAndDispatchChange(currentSegment + 1);
          break;
        }
        case 'Tab': {
          if (currentSegment !== lastSegment) {
            e.preventDefault();
            selectSegmentAndDispatchChange(currentSegment + 1);
          }
          break;
        }
        case 'ArrowUp': {
          e.preventDefault();
          doStep(true);
          break;
        }
        case 'ArrowDown': {
          e.preventDefault();
          doStep(false);
          break;
        }
        case 'a':
        case 'p': {
          // checking metaKey to prevent breaking of ctrl/cmd+a
          if (currentSegment === 2 && !e.nativeEvent.metaKey) {
            e.preventDefault();
            dispatchChange(convertHoursTo24(hours, e.key === 'a'));
          }
          break;
        }
        default:
          if (!Number.isNaN(Number(e.key))) {
            e.preventDefault();
            return handleTyping(e);
          }
      }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (!ref.current) return;
      const data = e.clipboardData.getData('text');
      const fullTimeParsed = parseFullTime(data);
      // trying to find time in the pasted text and use it if there is such
      if (fullTimeParsed) return dispatchChange(...fullTimeParsed);
      // if no full match we check is pasted text matching current segment (hours/minutes)
      // if matching we modify only this segment
      const currentSegment = getSegment(ref.current);
      if (currentSegment === 0) {
        // we try to interpret pasted data as 12 hours format for AM/PM locales
        if (is12HoursLocale && isHours(data, true)) {
          dispatchChange(convertHoursTo24(data, isAm));
          // for 24h locales or if input not matching 12h format we just try 24h format
        } else if (isHours(data, false)) {
          dispatchChange(data);
        }
      }
      if (currentSegment === 1 && isMinutes(data)) {
        dispatchChange(undefined, data);
      }
    };

    const handleFocus = () => {
      lastKnownSegmentRef.current = 0;
    };

    const handleBlur = () => {
      setIsTyping(false);
      lastKnownSegmentRef.current = undefined;
      // we apply wip value if it is valid
      dispatchChange(
        ...correctValues(hours, minutes, {
          is12HoursLocale,
          isAm,
        }),
      );
    };

    const handleMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
      // preventing conflicting default select event
      e.preventDefault();
      selectSegment();
      dispatchChange(hoursExternal, minutes);
    };

    const handleBeforeInput = (e: React.FormEvent<HTMLInputElement>) => {
      // prevent jumping to the start of the input on unhandled char
      e.preventDefault();
    };

    return (
      <LegacyInput
        value={constructValue(hours, minutes, isAm, is12HoursLocale)}
        ref={mergeRefs(ref, forwardedRef)}
        onMouseUp={handleMouseUp}
        onFocus={handleFocus}
        onBlurCapture={handleBlur}
        onKeyDown={handleKeyDown}
        onBeforeInput={handleBeforeInput}
        onPaste={handlePaste}
        onChange={noop}
        type="text"
        className={styles.timeInput__field}
        {...restProps}
      />
    );
  },
);
