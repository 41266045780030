import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import { LegacyStyleButton } from '@shared/components/legacy/LegacyStyleButton';
import LegacyDiv from '@shared/components/LegacyDiv';
import { ensureValue } from '@shared/utils';
import z from '@shared/utils/zod';

import {
  checkIsAm,
  convertHoursTo12,
  convertHoursTo24,
  flipHoursAmPm,
} from './utils';

import styles from './index.module.scss';

const isHtmlElement = (node: unknown): node is HTMLElement => {
  return node instanceof HTMLElement;
};

interface ItemProps {
  value: string;
  onClick: (value: string) => void;
  isSelected: boolean;
  isFocused: boolean;
  onTouchEnd: (value: string) => void;
}

const Item = ({
  value,
  onClick,
  isSelected,
  isFocused,
  onTouchEnd,
}: ItemProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isFocused && ref.current) ref.current.focus({ preventScroll: true });
  }, [isFocused]);

  useEffect(() => {
    if (isSelected && ref.current) ref.current.scrollIntoView();
    // we scroll into view only on mount, so dependency array is empty
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => onClick(value);
  const handleTouchEnd = (e: React.TouchEvent) => {
    e.preventDefault();
    onTouchEnd(value);
  };

  return (
    <LegacyStyleButton
      ref={ref}
      onClick={handleClick}
      onTouchEnd={handleTouchEnd}
      className={classNames(
        styles.timeInput__dropdownItem,
        isSelected && styles.timeInput__dropdownItem_selected,
      )}>
      {value}
    </LegacyStyleButton>
  );
};

interface ListProps {
  values: string[];
  selected: string;
  isActive: boolean;
  onClick: (value: string) => void;
  onTouchEnd: (value: string) => void;
}

const List = ({
  values,
  selected,
  isActive,
  onClick,
  onTouchEnd,
}: ListProps) => {
  return (
    <LegacyDiv className={styles.timeInput__dropdownList}>
      {values.map((value) => {
        const isSelected = value === selected;
        return (
          <Item
            key={value}
            value={value}
            isSelected={isSelected}
            isFocused={isSelected && isActive}
            onClick={onClick}
            onTouchEnd={onTouchEnd}
          />
        );
      })}
    </LegacyDiv>
  );
};

const generateValues = (start: number, max: number, step: number) => {
  const values = [];
  for (let i = start; i <= max; i += step) {
    if (i > max) break;
    values.push(String(i).padStart(2, '0'));
  }
  return values;
};

interface TimeInputDropdownProps {
  value: string;
  onChange: (value: string) => void;
  is12HoursLocale: boolean;
  minutesStep: number;
  onClose: () => void;
  'data-test-id': string;
}

export const TimeInputDropdown = ({
  value,
  onChange,
  is12HoursLocale,
  minutesStep,
  onClose,
  'data-test-id': dataTestId,
}: TimeInputDropdownProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [activeSegment, setActiveSegment] = useState(0);
  const lastSegment = is12HoursLocale ? 2 : 1;

  // close the dropdown if user scrolls outside
  useEffect(() => {
    const handleScrollOutside = (e: Event) => {
      if (!isHtmlElement(e.target)) return;
      if (ref.current && !ref.current.contains(e.target)) onClose();
    };
    document.addEventListener('scroll', handleScrollOutside);
    return () => document.removeEventListener('scroll', handleScrollOutside);
  }, [onClose]);

  const [hours, minutes] = z
    .tuple([z.string(), z.string()])
    .rest(z.string())
    .parse(value.split(':'));

  const isAm = checkIsAm(hours);

  const switchSegment = (newSegment: number) => {
    if (newSegment !== activeSegment) setActiveSegment(newSegment);
  };

  const changeHours = (hoursUpdate: string) => {
    const newHours = is12HoursLocale
      ? convertHoursTo24(hoursUpdate, isAm)
      : hoursUpdate;
    if (newHours !== hours) onChange(`${newHours}:${minutes}`);
    switchSegment(0);
  };
  const changeMinutes = (minutesUpdate: string) => {
    if (minutesUpdate !== minutes) onChange(`${hours}:${minutesUpdate}`);
    switchSegment(1);
  };

  const changeAmPm = (ampm: string) => {
    const isAmUpdate = ampm === 'AM';
    if (isAmUpdate !== isAm) onChange(`${flipHoursAmPm(hours)}:${minutes}`);
    switchSegment(2);
  };

  const segmentHandlers = [changeHours, changeMinutes, changeAmPm];

  const hoursList = is12HoursLocale
    ? ['12', ...generateValues(1, 11, 1)]
    : generateValues(0, 23, 1);
  const minutesList = generateValues(0, 59, minutesStep);

  const segmentLists = [
    hoursList,
    minutesStep > 59 ? [] : minutesList,
    ['AM', 'PM'],
  ];
  const segmentSelectedValues = [
    is12HoursLocale ? convertHoursTo12(hours) : hours,
    minutes,
    isAm ? 'AM' : 'PM',
  ];

  const handleStep = (step: 1 | -1) => {
    const focusedValue = segmentSelectedValues[activeSegment];
    const activeList = segmentLists[activeSegment];

    if (!focusedValue || !activeList) return;

    const index = activeList.indexOf(focusedValue);
    const candidate = activeList[index + step];
    if (candidate) {
      const changeFn = segmentHandlers[activeSegment];
      if (!changeFn) return;
      changeFn(candidate);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isHtmlElement(e.target)) return;
    e.preventDefault();
    switch (e.key) {
      case 'Enter':
      case 'Escape':
        onClose();
        break;
      case 'ArrowLeft':
        if (activeSegment) setActiveSegment(activeSegment - 1);
        break;
      case 'ArrowRight':
        if (activeSegment < lastSegment) setActiveSegment(activeSegment + 1);
        break;
      case 'Tab':
        setActiveSegment(activeSegment === lastSegment ? 0 : activeSegment + 1);
        break;
      case 'ArrowUp':
      case 'ArrowDown': {
        const isUp = e.key === 'ArrowUp';
        handleStep(isUp ? -1 : 1);
        const successor = e.target[isUp ? 'previousSibling' : 'nextSibling'];
        if (!isHtmlElement(successor)) return;
        if (!isHtmlElement(e.target.parentElement)) return;
        const { parentElement: parent } = e.target;

        successor.focus({ preventScroll: true });
        if (parent.scrollTop > successor.offsetTop) {
          successor.scrollIntoView(true);
        }
        if (parent.scrollTop + parent.offsetHeight <= successor.offsetTop) {
          successor.scrollIntoView(false);
        }
        break;
      }
      default:
    }
  };

  // close the dropdown if user somehow focuses outside
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (ref.current && !ref.current.contains(e.relatedTarget)) {
      onClose();
    }
  };

  const segments = is12HoursLocale ? [0, 1, 2] : [0, 1];

  return (
    // eslint complains on onKeyDown, but it just intercepts the events from buttons
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <LegacyDiv
      data-test-id={dataTestId}
      ref={ref}
      className={styles.timeInput__dropdownWrapper}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}>
      {segments.map((i) => (
        <List
          key={i}
          values={ensureValue(segmentLists[i])}
          isActive={activeSegment === i}
          selected={ensureValue(segmentSelectedValues[i])}
          onClick={ensureValue(segmentHandlers[i])}
          onTouchEnd={ensureValue(segmentHandlers[i])}
        />
      ))}
    </LegacyDiv>
  );
};
