import React, { ReactNode } from 'react';

import { Link as ReachRouterLink } from '@reach/router';
import classnames from 'classnames';

import { useUrlParamsPreservation } from '@shared/utils/route';

import './index.scss';

type LinkTarget = '_blank' | '_parent' | '_self' | '_top';

type LinkBaseProps = {
  external?: boolean;
  to: string;
  children?: ReactNode;
  className?: string;
  onClick?: () => unknown;
  target?: LinkTarget;
  title?: string;
  'data-test-id': string;
  state?: unknown;
};

export type LinkProps = LinkBaseProps & { preserveParams?: boolean };

const LinkBase = ({
  external,
  to,
  children,
  className,
  onClick,
  target,
  title,
  state,
  'data-test-id': dataTestId = 'link',
}: LinkBaseProps) => {
  return external ? (
    <a
      data-test-id={dataTestId}
      className={classnames(className)}
      href={to}
      onClick={onClick}
      target={target}
      title={title}>
      {children}
    </a>
  ) : (
    <ReachRouterLink
      data-test-id={dataTestId}
      className={classnames(className)}
      to={to}
      onClick={onClick}
      title={title}
      state={state}>
      {children}
    </ReachRouterLink>
  );
};

const LinkWithParamsPreservation = ({ to, ...rest }: LinkBaseProps) => {
  const preserveParams = useUrlParamsPreservation();
  return <LinkBase to={preserveParams(to)} {...rest} />;
};

export const Link = ({ preserveParams = false, ...props }: LinkProps) => {
  // params preservation moved to standalone component to prevent too many redundant updates coming from location changes
  const LinkComponent = preserveParams ? LinkWithParamsPreservation : LinkBase;
  return <LinkComponent {...props} />;
};

export default Link;
