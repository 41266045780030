import React from 'react';

import { Link } from '@reach/router';

import { Button } from '@shared/components/Button';
import LegacyDiv from '@shared/components/LegacyDiv';
import UpdatePasswordForm from '@shared/components/UpdatePassword/UpdatePasswordForm';
import { useApi, useFetch } from '@shared/containers/hooks/api';

import {
  fetchCustomerPasswordEntropy,
  updateAdvancedPassword,
} from '@ManagerPortal/api/authenticationSettings';

interface AdvancedPasswordProps {
  token?: string;
}

const AdvancedPassword: React.FC<AdvancedPasswordProps> = ({ token }) => {
  const { data: entropyForCustomer } = useFetch<number, (string | undefined)[]>(
    fetchCustomerPasswordEntropy,
    [token],
  );

  const [{ isSuccess }, updatePassword] = useApi(updateAdvancedPassword);

  if (!token) {
    return null;
  }

  if (entropyForCustomer && entropyForCustomer >= 0) {
    return isSuccess ? (
      <LegacyDiv className="text-center">
        <Link to="/" className="margin-4-top" data-test-id="login-link">
          <Button text="Click here to log in" data-test-id="login-button" />
        </Link>
      </LegacyDiv>
    ) : (
      <UpdatePasswordForm
        onUpdatePassword={updatePassword}
        token={token}
        entropyForCustomer={entropyForCustomer}
      />
    );
  }

  return (
    <LegacyDiv className="text-center">
      <h4>This link is no longer valid.</h4>
      <LegacyDiv className="text-center">
        <Link
          data-test-id="page-x"
          className="auth-page-link"
          to="/resetpassword">
          Reset password
        </Link>
      </LegacyDiv>
    </LegacyDiv>
  );
};

export default AdvancedPassword;
