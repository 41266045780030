import React, { ForwardedRef, forwardRef, useState } from 'react';

import { LegacyInput } from '@shared/components/legacy/LegacyInput';
import { LegacyStyleButton } from '@shared/components/legacy/LegacyStyleButton';
import LegacyDiv from '@shared/components/LegacyDiv';
import Tooltip from '@shared/components/Tooltip';

import { decimalToHexColor } from '@ManagerPortal/components/utils/color';

import useTranslate from '../../hooks/useTranslate';
import { withFormControl } from '../Form';
import Label from '../Label';
import { DEFAULT_COLORS } from './colors';

import styles from './index.module.scss';

interface IColorOptionProps {
  color: string;
  onClick: () => void;
}
const ColorOption = ({ color, onClick }: IColorOptionProps) => {
  return (
    <LegacyStyleButton
      type="button"
      onClick={onClick}
      className={styles.colorOption}
      style={{
        backgroundColor: decimalToHexColor(color),
      }}
    />
  );
};
export interface IColorPickerProps {
  colors?: string[];
  disableInput?: boolean;
  value: string;
  label?: string;
  info?: string;
  onChange: (color: string) => void;
}

export const ColorPicker = forwardRef(
  (
    {
      colors = DEFAULT_COLORS,
      disableInput = false,
      value,
      onChange,
      label = '',
      info,
      ...props
    }: IColorPickerProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [showColorOptions, setShowColorOptions] = useState(false);
    const translate = useTranslate();

    return (
      <>
        {label && <Label label={label} />}
        <LegacyDiv className={styles.colorPicker__wrapper}>
          <LegacyDiv className={styles.colorPicker__prepend}>
            <Tooltip
              data-test-id="colorPickerTooltip"
              arrow
              isOpen={showColorOptions}
              useHover={false}
              direction="down"
              content={
                <LegacyDiv className={styles.colorPalette}>
                  {colors.map((color) => (
                    <ColorOption
                      key={color}
                      color={color}
                      onClick={() => {
                        setShowColorOptions(false);
                        onChange(color);
                      }}
                    />
                  ))}
                </LegacyDiv>
              }>
              <ColorOption
                color={value}
                onClick={() => {
                  setShowColorOptions(!showColorOptions);
                }}
              />
            </Tooltip>
          </LegacyDiv>
          <LegacyDiv className={styles.colorPicker__input}>
            <LegacyInput
              ref={ref}
              value={value}
              disabled={disableInput}
              onChange={(e) => onChange(e.target.value)}
              {...props}
            />
          </LegacyDiv>
        </LegacyDiv>
        {info && <span className={styles.infoMsg}>{translate(info)}</span>}
      </>
    );
  },
);

export const FormColorPicker = withFormControl<IColorPickerProps, string>(
  ColorPicker,
);
