import { MutableRefObject, RefCallback } from 'react';

import { isNil } from '..';

type Ref<T> = RefCallback<T> | MutableRefObject<T> | null | undefined;

export const mergeRefs =
  <T>(...refs: Ref<T>[]) =>
  (element: T) =>
    refs.forEach((ref) => {
      if (isNil(ref)) return;

      if (typeof ref === 'function') {
        return ref(element);
      }

      if (typeof ref === 'object' && 'current' in ref) {
        ref.current = element;
        return;
      }

      throw new Error(
        `All arguments of "${mergeRefs.name}" should be functions, ref objects or nullish`,
      );
    });
