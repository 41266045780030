import { PERMISSION, PERMISSION_LEVEL } from './moduleAndPermissions';

const reportTypes = {
  salaryFile: { id: 1, name: 'salaryFile', accessNames: [] },
  salaryReport: { id: 2, name: 'salaryReport', accessNames: [] },
  schedulePeriod: {
    id: 3,
    name: 'schedulePeriod',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
    ],
  },
  scheduleCost: {
    id: 4,
    name: 'scheduleCost',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.SALARY, level: PERMISSION_LEVEL.READ },
    ],
  },
  timeCost: {
    id: 5,
    name: 'timeCost',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.SALARY, level: PERMISSION_LEVEL.READ },
    ],
  },
  dailySchedule: {
    id: 6,
    name: 'dailySchedule',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
    ],
  },
  salaryDetails: {
    id: 7,
    name: 'salaryDetails',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
    ],
  },
  punchedHours: {
    id: 8,
    name: 'punchedHours',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.SALARY, level: PERMISSION_LEVEL.READ },
    ],
  },
  summaryPerEmployee: {
    id: 9,
    name: 'summaryPerEmployee',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
    ],
  },
  absence: {
    id: 10,
    name: 'absence',
    accessNames: [
      { name: PERMISSION.SALARY, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
    ],
  },
  schedule: {
    id: 11,
    name: 'schedule',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
    ],
  },
  detailedSchedule: {
    id: 12,
    name: 'detailedSchedule',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
    ],
  },
  timeTracker: {
    id: 13,
    name: 'timeTracker',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
    ],
  },
  employeeHours: {
    id: 14,
    name: 'employeeHours',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
    ],
  },
  salaryDetailsCostCentre: {
    id: 15,
    name: 'salaryDetailsCostcenter',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
    ],
  },
  payroll: {
    id: 16,
    name: 'payroll',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.SALARY, level: PERMISSION_LEVEL.READ },
    ],
  },
  absencePercentage: {
    id: 17,
    name: 'absencePercentage',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
    ],
  },
  monthlySchedule: {
    id: 18,
    name: 'monthlySchedule',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
    ],
  },
  presence: {
    id: 19,
    name: 'presence',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
    ],
  },
  shiftStaffing: {
    id: 20,
    name: 'shiftStaffing',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
    ],
  },
  customReports: {
    id: 21,
    name: 'customReport',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PEOPLE, level: PERMISSION_LEVEL.READ },
    ],
  },
  detailedBreaks: {
    id: 22,
    name: 'detailedBreaks',
    accessNames: [
      { name: PERMISSION.SCHEDULING, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.ABSENCE, level: PERMISSION_LEVEL.READ },
      { name: PERMISSION.PUNCHES, level: PERMISSION_LEVEL.READ },
    ],
  },
};

export default reportTypes;
