import React, { ReactNode } from 'react';

import classNames from 'classnames';

interface LegacyStyleButtonProps {
  children?: ReactNode;
  className?: string;

  // All other properties
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [prop: string]: any;
}

export const LegacyStyleButton = React.forwardRef<
  HTMLButtonElement,
  LegacyStyleButtonProps
>(({ children, className, ...rest }, ref) => {
  return (
    <button
      ref={ref}
      className={classNames('legacyStyleButton', className || '')}
      {...rest}>
      {children}
    </button>
  );
});
