import React, { ForwardedRef, forwardRef, useMemo } from 'react';

import classNames from 'classnames';

import useToggle from '@shared/components/hooks/useToggle';
import useTranslate from '@shared/components/hooks/useTranslate';
import { Icon } from '@shared/components/Icon';
import LegacyDiv from '@shared/components/LegacyDiv';
import Tooltip from '@shared/components/Tooltip';
import { isNil } from '@shared/utils';
import { format, newDate } from '@shared/utils/time';
import { LOCALE_TIME_FORMAT } from '@shared/utils/time/constants';

import { withFormControl } from '../Form';
import Label from '../Label';
import { TimeInputDropdown } from './TimeInputDropdown';
import { TimeInputField } from './TimeInputField';

import styles from './index.module.scss';

export interface TimeInputProps {
  /** Value must be presented in standard HH:mm format */
  value: string;
  /** Name attribute of the input complying html standards  */
  name: string;
  /** Caption for the input placed on top */
  label?: string;
  /** Error message shown below the input */
  error?: string;
  /** Supporting text */
  info?: string;
  /** Prohibit user from editing */
  disabled?: boolean;
  /** Will put an  asterisk to the label */
  required?: boolean;
  /** Callback fired on user doing changes in the input */
  onChange: (value: string) => void;
  /** Describes what are possible values for minutes - they should be divisible by the step. It also affects arrow navigation and dropdown */
  minutesStep?: number;
  /** Data attribute for the input helping to test the solution  */
  'data-test-id': string;
  /** This flag used to force 12 or 24 format declining the locale picked by the user  */
  use12Hours?: boolean;
  /** ID attribute of the input complying html standards  */
  id?: string;
}

export const TimeInputComponent = (
  {
    minutesStep = 1,
    use12Hours,
    value,
    onChange,
    label,
    required,
    id,
    error,
    info,
    disabled,
    'data-test-id': dataTestId,
    ...props
  }: TimeInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const translate = useTranslate();
  const is12HoursLocale = useMemo(() => {
    if (!isNil(use12Hours)) return use12Hours;
    return format(newDate(), LOCALE_TIME_FORMAT).length > 5;
  }, [use12Hours]);

  const [isOpen, { uncheck, check, toggle }] = useToggle(false);

  const handleTouchEnd = (e: React.TouchEvent) => {
    e.preventDefault();
    toggle();
  };

  // we use mouseDown instead of click to not open dropdown again if was already closed on lost focus
  const handleIconMouseDown = (e: React.MouseEvent) => {
    if (!isOpen) {
      // prevent default to not trigger blur (otherwise dropdown will detect it and close)
      e.preventDefault();
      check();
    }
  };

  return (
    <Tooltip
      key={String(is12HoursLocale)}
      isOpen={isOpen}
      direction="down-start"
      padding={0}
      className={styles.timeInput__triggerWrapper}
      // we use fragment here to prevent tooltip library from complaining about missing content (in case of closed dropdown
      content={
        <>
          {isOpen && (
            <TimeInputDropdown
              data-test-id={`${dataTestId}__dropdown`}
              is12HoursLocale={is12HoursLocale}
              minutesStep={minutesStep}
              value={value}
              onChange={onChange}
              onClose={uncheck}
            />
          )}
        </>
      }>
      <>
        {label && <Label htmlFor={id} label={label} required={required} />}
        <LegacyDiv
          onTouchEnd={handleTouchEnd}
          className={classNames(
            styles.timeInput__fieldWrapper,
            isOpen && styles.timeInput__fieldWrapper_opened,
          )}>
          <TimeInputField
            data-test-id={dataTestId}
            ref={ref}
            is12HoursLocale={is12HoursLocale}
            minutesStep={minutesStep}
            value={value}
            onChange={onChange}
            id={id}
            disabled={disabled}
            {...props}
          />
          <Icon
            role="button"
            icon="clock"
            disabled={disabled}
            className={styles.timeInput__icon}
            onMouseDown={handleIconMouseDown}
          />
        </LegacyDiv>

        {error && <span className={styles.errorMsg}>{translate(error)}</span>}

        {info && (
          <span className="font__xSmallRegular margin-1-top">
            {translate(info)}
          </span>
        )}
      </>
    </Tooltip>
  );
};

export const TimeInput = forwardRef(TimeInputComponent);
TimeInput.displayName = 'TimeInput';

export const FormTimeInput = withFormControl(TimeInput);
